import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, Observable, of, throwError } from 'rxjs';

import { DOMService, GenericHttpService } from '@shared/services';
import { FILE_CONTENT_TYPE } from '@shared/constants';
import { environment } from '@environment';
import { SpinnerCoverService } from '@features/spinner';
import { ErrorHandlerService } from '@features/error-handler';
import { mapErrorMessage } from '@features/error-handler/utils';
import { formatBytes } from '@features/files/utils';
import { FILE_TYPE_IMAGE, MAX_FILE_SIZE, NOT_ALLOWED_IMAGE_TYPE } from '@features/files/constants';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(
    private genericHttp: GenericHttpService,
    private rootHttp: HttpClient,
    private spinnerService: SpinnerCoverService,
    private errorHandlerService: ErrorHandlerService,
    private domService: DOMService
  ) {}

  public uploadMultipleFiles(files: any[], isDocument = false): Observable<string[]> {
    if (files.length) {
      this.spinnerService.start();
      this.errorHandlerService.hide();

      return forkJoin(files?.map((file) => this.uploadFile(file, false).pipe(catchError((err) => of(err))))).pipe(
        map((fileUrls) => fileUrls || [])
      );
    }

    return of([]);
  }

  public checkUploadFile(file, isDocument = false, isCleanError = true) {
    if (isCleanError) {
      this.errorHandlerService.hide();
    }

    if (!isDocument) {
      const formatedB = formatBytes(file.size);
      if (formatedB > MAX_FILE_SIZE) {
        this.errorHandlerService.newError(`File size must be below ${MAX_FILE_SIZE}mb`);
        this.domService.scrollIntoErrorView();
        return false;
      }

      const [fileType, fileTypeExtension] = file.type.split('/');
      if (fileType !== FILE_TYPE_IMAGE) {
        this.errorHandlerService.newError(`File type must be an ${FILE_TYPE_IMAGE}`);
        this.domService.scrollIntoErrorView();
        return false;
      }

      if (NOT_ALLOWED_IMAGE_TYPE.includes(fileTypeExtension)) {
        this.errorHandlerService.newError(`${fileTypeExtension} format is not allowed`);
        this.domService.scrollIntoErrorView();
        return false;
      }
    }

    return true;
  }

  public uploadFile(file: any, isWithSpinner = true) {
    if (!file || !file.name || !file.type) {
      return of(file);
    }

    const name = file.name;
    const lastIndex = name.lastIndexOf('.');
    const fileExtension = name.substring(lastIndex + 1);
    const fileName = name.substring(0, lastIndex);

    if (isWithSpinner) {
      this.spinnerService.start();
      this.errorHandlerService.hide();
    }

    const fixedContentType = file.type !== '' ? file.type : FILE_CONTENT_TYPE[fileExtension];

    const newFileInstance = <File>Object.assign(new Blob([file], { type: fixedContentType }), { name: fileName });

    return this.genericHttp
      .post(
        'files/start-upload',
        {
          name: name,
          // We have a toLowerCase() here because any file type that is case sensitive ends up messing with the signature. Only files with macros
          // seem to have a case sensitive name e.g.: 'application/vnd.ms-excel.sheet.macroEnabled.12'. File object types are always lowercase
          // so then when generating and comparing the signature they doesn't match.
          contentType: fixedContentType.toLowerCase(),
        },
        false
      )
      .pipe(
        switchMap(({ uploadId, uploadUrl }) =>
          this.rootHttp.put(uploadUrl, newFileInstance).pipe(
            map(() => `${environment.filesStorage}/${fileName}_${uploadId}.${fileExtension}`),
            catchError((err) => {
              this.errorHandlerService.newError(mapErrorMessage(err));
              return throwError('');
            })
          )
        ),
        catchError((err) => {
          this.errorHandlerService.newError(mapErrorMessage(err));
          return throwError('');
        }),
        tap(() => isWithSpinner && this.spinnerService.end())
      );
  }
}
