import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { IUserStorageModel } from '@features/auth/models';
import { CognitoUserService } from '@features/auth/services';
import { UserActions } from '@features/auth/state/actions';
import { AppRoutesEnum, RouteQueryParamsEnum } from '@shared/enums';
import { InitActions } from '@shared/state/actions';
import { mapPayload } from '@shared/utils';

@Injectable()
export class UserEffects {
  public userInit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InitActions.pageInitialized),
      map(() => this.cognitoUserService.getStorageUserData()),
      map((userInfo: IUserStorageModel) => UserActions.storageUserChanged({ payload: userInfo }))
    )
  );

  public logout$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logoutInitiated, UserActions.invalidTokenDetected),
        tap(() => this.cognitoUserService.signOut())
      ),
    { dispatch: false }
  );

  public userCleared$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logoutInitiated),
        tap(() => {
          this.router.navigate([AppRoutesEnum.AUTH, AppRoutesEnum.AUTH_LOGIN]);
        })
      ),
    { dispatch: false }
  );

  public invalidTokenDetected$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.invalidTokenDetected),
        tap(() => {
          this.router.navigate([AppRoutesEnum.AUTH, AppRoutesEnum.AUTH_LOGIN], {
            queryParams: { [RouteQueryParamsEnum.REDIRECT_URL]: window.location.pathname },
          });
        })
      ),
    { dispatch: false }
  );

  public userIsLoggedInAndAppInitialized$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.storageUserChanged),
      mapPayload(),
      filter(Boolean),
      map(() => InitActions.pageLoggedInInitialized())
    )
  );

  public initCognitoProps$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InitActions.pageLoggedInInitialized),
        tap(() => {
          this.cognitoUserService.initCognitoProps();
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private cognitoUserService: CognitoUserService, private router: Router) {}
}
