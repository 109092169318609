import { IFilterOptionModel } from '@shared/models';

import { LenderDealStatusEnum, SponsorDealStatusEnum } from '@features/deals/enums';

import { COUNTRIES_WITH_MIXED } from './countries.constants';
import { ASSET_TYPES_WITH_MULTI } from './asset-types.constants';
import { LOAN_TYPES } from './loan-types.constants';
import { STRATEGIES } from './strategies.constants';
import { MandateTypeEnum } from '@shared/enums';

export const LENDER_STATUS_FILTER: IFilterOptionModel[] = [
  {
    key: LenderDealStatusEnum.NDA,
    value: LenderDealStatusEnum.NDA,
  },
  {
    key: LenderDealStatusEnum.MARKETING,
    value: LenderDealStatusEnum.MARKETING,
  },
  {
    key: LenderDealStatusEnum.TENDER,
    value: LenderDealStatusEnum.TENDER,
  },
  {
    key: LenderDealStatusEnum.LEGALS,
    value: LenderDealStatusEnum.LEGALS,
  },
  {
    key: LenderDealStatusEnum.DECLINED,
    value: LenderDealStatusEnum.DECLINED,
  },
];

export const SPONSOR_STATUS_FILTER: IFilterOptionModel[] = [
  {
    key: SponsorDealStatusEnum.ANALYSIS,
    value: SponsorDealStatusEnum.ANALYSIS,
  },
  {
    key: SponsorDealStatusEnum.MARKETING,
    value: SponsorDealStatusEnum.MARKETING,
  },
  {
    key: SponsorDealStatusEnum.LEGALS,
    value: SponsorDealStatusEnum.LEGALS,
  },
];

export const MANDATE_TYPES_FILTER: IFilterOptionModel[] = [
  {
    key: MandateTypeEnum.LENDER_CRITERIA,
    value: 'Lending criteria',
  },

  {
    key: MandateTypeEnum.INVESTMENT_CRITERIA,
    value: 'Investment criteria',
  },
];

export const COUNTRIES_FILTER: IFilterOptionModel[] = COUNTRIES_WITH_MIXED;

export const ASSET_TYPES_FILTER: IFilterOptionModel[] = ASSET_TYPES_WITH_MULTI;

export const STRATEGIES_FILTER: IFilterOptionModel[] = STRATEGIES;

export const LOAN_TYPES_FILTER: IFilterOptionModel[] = LOAN_TYPES;
