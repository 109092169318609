import { Component, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '@shared/models';
import { InitActions } from '@shared/state/actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  constructor(private store$: Store<IAppState>) {
    this.markAppInit();
  }

  private markAppInit(): void {
    this.store$.dispatch(InitActions.pageInitialized());
  }
}
