export const paramsMapper = (params: any) => {
  const mappedParams = {};

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (value) {
      mappedParams[key] = value;
    }
  });
  return mappedParams;
};
