import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IRouterStateUrlModel } from '@shared/models';
import { AppRoutesEnum } from '@shared/enums';

export const selectRouter = createFeatureSelector<RouterReducerState<IRouterStateUrlModel>>('router');

// NOTE: The getSelectors method works with the routerReducer provided by @ngrx/router-store. If you use a custom serializer, you'll need to provide your own selectors
export const { selectUrl } = getRouterSelectors(selectRouter);

export const selectRouterUrl = createSelector(selectRouter, (routerState) => routerState?.state?.url);

export const selectRouteParams = createSelector(selectRouter, (routerState) => routerState?.state.params || {});

export const selectQueryParams = createSelector(selectRouter, (routerState) => routerState?.state.queryParams || {});

export const selectRouteParamFactory = (paramName) => createSelector(selectRouteParams, (params) => params[paramName]);

export const selectQueryParam = (paramName) => createSelector(selectQueryParams, (params) => params[paramName]);

export const selectBooleanQueryParam = (paramName) =>
  createSelector(selectQueryParams, (params) => params[paramName] === 'true');

export const isCurrentPage = (page: AppRoutesEnum) =>
  createSelector(selectUrl, (currentUrl) => {
    if (currentUrl) {
      const checkSubRoute = (subRoute) => currentUrl.indexOf(subRoute) > -1;
      return checkSubRoute(page);
    }
    return false;
  });

export const isCurrentPageFullMatch = (url: string) =>
  createSelector(selectUrl, (currentUrl) => {
    if (currentUrl) {
      return currentUrl.split('?')[0] === url;
    }

    return false;
  });
