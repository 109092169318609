import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouteParamsService {
  public routeData$: Observable<{}>;

  private activatedRouteSubj$: BehaviorSubject<ActivatedRoute> = new BehaviorSubject(null);

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.setupActivatedRoute();
    this.setupRouteData();
  }

  private setupRouteData() {
    this.routeData$ = this.activatedRouteSubj$.pipe(map((activatedRoute) => activatedRoute?.snapshot?.data || {}));
  }

  private setupActivatedRoute() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        switchMap(() => {
          const route = this.routeFirstChild();

          return of(route || null);
        })
      )
      .subscribe((route) => this.activatedRouteSubj$.next(route));
  }

  private routeFirstChild(): ActivatedRoute {
    // Importing ActivatedRoute in service/effects will inject root activated root
    // Hear we are traversing to find top level activated route
    let route = this.activatedRoute;

    // Parent activated route doesn't have access to child params, so traversing to last child
    while (route?.firstChild) {
      route = route.firstChild;
    }

    return route;
  }
}
