import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CurrencyRatesEffects } from '@features/currency-rates/state/effects';
import { currencyRatesFeatureName } from '@features/currency-rates/state/selector';
import { currencyRatesFeatureReducers } from '@features/currency-rates/state/reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(currencyRatesFeatureName, currencyRatesFeatureReducers),
    EffectsModule.forFeature([CurrencyRatesEffects]),
  ],
})
export class CurrencyRatesModule {}
