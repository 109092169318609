import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ProfileActions, UserActions } from '@features/auth/state/actions';
import { getCurrentTenant } from '@features/auth/state/selectors';
import { ProfileService } from '@features/auth/services';
import { mapPayload } from '@shared/utils';
import { SpinnerCoverService } from '@features/spinner';
import { IUserPreferencesUpdateModel, IUserResponseModel, IUserUpdateModel } from '@features/auth/models';
import { InitActions } from '@shared/state/actions';
import { ErrorHandlerActions, mapErrorAction } from '@features/error-handler';
import { checkIsUserAdmin, waitUntilRouterInitialized } from '@features/auth/utils';
import { AppRoutesEnum, TenantPropertyEnum } from '@shared/enums';
import { IAppState, ITenantUpdateModel } from '@shared/models';
import { selectRouterUrl } from '@app/shared/state/selectors';

@Injectable()
export class ProfileEffects {
  public refineUserData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        InitActions.pageLoggedInInitialized,
        ProfileActions.profileChangeSuccess,
        ProfileActions.companyChangeSuccess,
        ProfileActions.userPreferencesChangeSuccess
      ),
      switchMap(() =>
        this.profileService.getProfileInfo().pipe(
          map((userInfo: IUserResponseModel) => UserActions.apiUserChanged({ payload: userInfo })),
          catchError((err) => of(ErrorHandlerActions.errorOccurred({ payload: err })))
        )
      )
    )
  );

  public profileUpdate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.profileChangeInitiated),
      mapPayload(),
      tap(() => this.spinnerService.start()),
      exhaustMap((userInfo: IUserUpdateModel) =>
        this.profileService.updateProfileInfo(userInfo).pipe(
          map(() => ProfileActions.profileChangeSuccess({ payload: userInfo })),
          catchError((error) => of(ProfileActions.profileChangeFailed({ payload: error })))
        )
      ),
      tap(() => this.spinnerService.end())
    )
  );

  public companyUpdate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.companyChangeInitiated),
      mapPayload(),
      withLatestFrom(this.store$.pipe(select(getCurrentTenant))),
      map(([formValue, currentTenant]) => ({
        ...formValue,
        [TenantPropertyEnum.PHONE]: {
          ...currentTenant[TenantPropertyEnum.PHONE],
          ...formValue[TenantPropertyEnum.PHONE],
        },
        [TenantPropertyEnum.ADDRESS]: {
          ...currentTenant[TenantPropertyEnum.ADDRESS],
          ...formValue[TenantPropertyEnum.ADDRESS],
        },
      })),
      tap(() => this.spinnerService.start()),
      exhaustMap((tenant: ITenantUpdateModel) =>
        this.profileService.updateProfileTenant(tenant).pipe(
          map((updatedTenant) => ProfileActions.companyChangeSuccess({ payload: updatedTenant })),
          catchError((error) => of(ProfileActions.companyChangeFailed({ payload: error })))
        )
      ),
      tap(() => this.spinnerService.end())
    )
  );

  public userPreferencesUpdate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.userPreferencesChangeInitiated),
      mapPayload(),
      tap(() => this.spinnerService.start()),
      exhaustMap((userInfo: IUserPreferencesUpdateModel) =>
        this.profileService.updateUserPreferences(userInfo).pipe(
          map(() => ProfileActions.userPreferencesChangeSuccess()),
          catchError(() => of(ProfileActions.userPreferencesChangeFailed()))
        )
      ),
      tap(() => this.spinnerService.end())
    )
  );

  public adminRedirect$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.apiUserChanged),
        waitUntilRouterInitialized(this.store$),
        mapPayload(),
        withLatestFrom(this.store$.pipe(select(selectRouterUrl))),
        filter(([user, routerUrl]) => {
          const checkSubRoute =
            !routerUrl.split('/').includes(AppRoutesEnum.SYSTEM) &&
            !routerUrl.split('/').includes(AppRoutesEnum.PROFILE_SETTINGS);

          return checkIsUserAdmin(user) && checkSubRoute;
        }),
        tap(() => this.router.navigate([AppRoutesEnum.SYSTEM, AppRoutesEnum.SYSTEM_TENANTS]))
      ),
    { dispatch: false }
  );

  public profileError$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(ProfileActions.profileChangeFailed, ProfileActions.companyChangeFailed), mapErrorAction())
  );

  constructor(
    private actions$: Actions,
    private store$: Store<IAppState>,
    private profileService: ProfileService,
    private spinnerService: SpinnerCoverService,
    private router: Router
  ) {}
}
