import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import { HttpQuerySerializer } from '@shared/serializers';

@Injectable({
  providedIn: 'root',
})
export class GenericHttpService {
  private apiUrl = environment.apiUrl;
  private suffix = '/api/v1/';

  constructor(private httpClient: HttpClient) {}

  public get = <T>(relativeUrl: string, queryParams: {} = {}, params: {} = {}): Observable<T> => {
    // some characters are incorrectly encoded so using custom serializer
    // https://github.com/angular/angular/issues/11058
    const queryParamsObj = new HttpParams({ encoder: new HttpQuerySerializer(), fromObject: queryParams });
    return this.httpClient.get<T>(`${this.apiUrl}${this.suffix}${relativeUrl}`, { params: queryParamsObj, ...params });
  };

  public post = <T>(relativeUrl: string, payload: T, isText: boolean = true): Observable<any> =>
    this.httpClient.post(
      `${this.apiUrl}${this.suffix}${relativeUrl}`,
      payload,
      isText ? { responseType: 'text' } : ({} as any)
    );

  public put = <T>(relativeUrl: string, payload: T): Observable<any> =>
    this.httpClient.put(`${this.apiUrl}${this.suffix}${relativeUrl}`, payload);

  // HttpClient.delete() cannot handle a body in its request https://github.com/angular/angular/issues/19438
  public delete = <T>(relativeUrl: string, payload?: any): Observable<any> =>
    this.httpClient.request('delete', `${this.apiUrl}${this.suffix}${relativeUrl}`, { body: payload });
}
