import { ConnectedPosition } from '@angular/cdk/overlay';

export type TooltipPosition = 'left' | 'top' | 'bottom' | 'right';

export interface TooltipOffset {
  offsetY: number;
  offsetX: number;
}

/**
 * Configurations for available tooltip positions according to what CDK expects.
 */
export const TOOLTIP_POSITIONS_AVAILABLE = {
  top: {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
  } as ConnectedPosition,
  bottom: {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
  } as ConnectedPosition,
  right: {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
  } as ConnectedPosition,
  left: {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
  },
};

/**
 * Defaul offsets for each tooltip position. (Basically it's always 10px away from trigger to give some space).
 */
export const TOOLTIP_DEFAULT_OFFSET = {
  top: {
    offsetX: 0,
    offsetY: -10,
  },
  bottom: {
    offsetX: 0,
    offsetY: 10,
  },
  right: {
    offsetX: 10,
    offsetY: 0,
  },
  left: {
    offsetX: -10,
    offsetY: 0,
  },
};

/**
 * Each LEFT, RIGHT, BOTTOM and TOP returns a list of available positions, starting for the desired position and
 * continuing with a few fallbacks in case the element doesn't fit in the available space when using the desired position.
 */
export const TOOLTIP_POSITIONS_SETUP = {
  // Tooltip positions with desired position (first array element), the rest are fallbacks if tooltip doesn't fit using desired position
  left: (offset: TooltipOffset) => [
    { ...offset, ...TOOLTIP_POSITIONS_AVAILABLE.left }, // Desired position
    { ...TOOLTIP_DEFAULT_OFFSET.right, ...TOOLTIP_POSITIONS_AVAILABLE.right },
    { ...TOOLTIP_DEFAULT_OFFSET.top, ...TOOLTIP_POSITIONS_AVAILABLE.top },
    { ...TOOLTIP_DEFAULT_OFFSET.bottom, ...TOOLTIP_POSITIONS_AVAILABLE.bottom },
  ],
  right: (offset: TooltipOffset) => [
    { ...offset, ...TOOLTIP_POSITIONS_AVAILABLE.right }, // Desired position
    { ...TOOLTIP_DEFAULT_OFFSET.left, ...TOOLTIP_POSITIONS_AVAILABLE.left },
    { ...TOOLTIP_DEFAULT_OFFSET.top, ...TOOLTIP_POSITIONS_AVAILABLE.top },
    { ...TOOLTIP_DEFAULT_OFFSET.bottom, ...TOOLTIP_POSITIONS_AVAILABLE.bottom },
  ],
  top: (offset: TooltipOffset) => [
    { ...offset, ...TOOLTIP_POSITIONS_AVAILABLE.top }, // Desired position
    { ...TOOLTIP_DEFAULT_OFFSET.bottom, ...TOOLTIP_POSITIONS_AVAILABLE.bottom },
  ],
  bottom: (offset: TooltipOffset) => [
    { ...offset, ...TOOLTIP_POSITIONS_AVAILABLE.bottom }, // Desired position
    { ...TOOLTIP_DEFAULT_OFFSET.top, ...TOOLTIP_POSITIONS_AVAILABLE.top },
  ],
};
