import { ActionReducerMap } from '@ngrx/store';

import { INavigationFeatureStateModel } from '@features/navigation/models';
import { navigationOpenReducer } from './navigation-open.reducer';
import { navigationCurrentItemReducer } from './navigation-current-item.reducers';

export const navigationFeatureReducers: ActionReducerMap<INavigationFeatureStateModel> = {
  open: navigationOpenReducer,
  currentNavItem: navigationCurrentItemReducer,
};
