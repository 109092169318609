import { createFeatureSelector, createSelector } from '@ngrx/store';

import { INavigationFeatureStateModel } from '@features/navigation/models';

export const navigationFeatureName = 'navigationFeature';

export const getNavigationFeature = createFeatureSelector<INavigationFeatureStateModel>(navigationFeatureName);

export const getNavigationOpenState = createSelector(getNavigationFeature, (state) => state.open);

export const getNavigationCurrentItemState = createSelector(getNavigationFeature, (state) => state.currentNavItem);
