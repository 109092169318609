import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule } from '@shared/components/icon';
import { AvatarComponent } from './avatar.component';

@NgModule({
  declarations: [AvatarComponent],
  exports: [AvatarComponent],
  imports: [CommonModule, IconModule],
})
export class AvatarModule {}
