import {
  INTEGER_NUMBER_FORMAT,
  MIN_AMOUNT,
  NUMBER_FORMAT,
  STRICT_MIN,
  WHITE_SPACE,
} from '@shared/constants/form.constants';
import { MAX_NUMBER_VALUE, MIN_NUMBER_VALUE } from './limits.constants';
import { MIN_LOAN_VIEW_VALUE } from '@features/deals/constants/deals-limits.constants';

export const REQUIRED_ERRORS = {
  required: 'The field is required.',
};

export const FORMAT_ERRORS = {
  pattern: 'Invalid format',
};

export const DEFAULT_INPUT_ERRORS = {
  ...REQUIRED_ERRORS,
  ...FORMAT_ERRORS,
};

export const EMAIL_ERRORS = {
  ...REQUIRED_ERRORS,
  [WHITE_SPACE]: 'Email is invalid.',
  pattern: 'Email is invalid.',
  email: 'Email is invalid.',
};

export const PERCENT_ERRORS = {
  [NUMBER_FORMAT]: 'Field should have only numbers',
  min: `Value should be greater than or equal to ${MIN_NUMBER_VALUE}`,
  max: `Value should be less than or equal to ${MAX_NUMBER_VALUE}`,
  required: REQUIRED_ERRORS.required,
};

export const NUMBER_ERRORS = {
  [INTEGER_NUMBER_FORMAT]: 'Field should have only integer numbers',
  [NUMBER_FORMAT]: 'Field should have only numbers',
  min: `Value should be greater than or equal to ${MIN_NUMBER_VALUE}`,
  [STRICT_MIN]: `Value should be greater than ${MIN_NUMBER_VALUE}`,
  [MIN_AMOUNT]: `Value should be greater than or equal to ${MIN_LOAN_VIEW_VALUE}`,
  required: REQUIRED_ERRORS.required,
};
