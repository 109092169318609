import { TenantPropertyEnum } from '@shared/enums';
import { ABOUT_MAX_LENGTH } from '@features/management/constants';

export const COMPANY_PROPERTY_LABEL = {
  [TenantPropertyEnum.NAME]: 'Company',
  [TenantPropertyEnum.SUB_TYPE]: 'Subtype',
  [TenantPropertyEnum.ABOUT]: `About`,
  [TenantPropertyEnum.ABOUT_MAX_CHAR]: `About (Max ${ABOUT_MAX_LENGTH} characters)`,
  [TenantPropertyEnum.PHONE]: 'Landline',
  [TenantPropertyEnum.WEBSITE_URL]: 'Website',
  [TenantPropertyEnum.ADDRESS]: 'Address',
  [TenantPropertyEnum.PRIMARY_ACCOUNT_TYPE]: 'Primary account type',
  [TenantPropertyEnum.DEFAULT_CURRENCY]: 'Default currency',
};
