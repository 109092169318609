import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import { IRouterStateUrlModel } from '@shared/models';

export class RouterSerializer implements RouterStateSerializer<IRouterStateUrlModel> {
  public serialize(routerState: RouterStateSnapshot): IRouterStateUrlModel {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams };
  }
}
