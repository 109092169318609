<navigation
  [sideMenuLinks]="sideMenuLinks$ | async"
  [profileLink]="profileLink$ | async"
  [isNavigationCollapsed]="isNavigationCollapsed$ | async"
  [currentNavigationItem]="currentNavigationItem$ | async"
  [profileInfo]="profileInfo"
  [accountType]="accountType$ | async"
  (navigationToggled)="onNavigationToggled()"
  (logout)="onLogout()"
  (switchAccount)="onSwitchAccount()"
  (userGuideClick)="onUserGuideClick()"
  (navigationItemChanged)="onNavigationItemChanged($event)"
></navigation>
