import { createAction, props } from '@ngrx/store';

import { IApiErrorModel } from '@shared/models';
import { IConvertCurrencyActionModel, ICurrencyRateModel } from '@features/currency-rates/models';

export const getCurrencyRatesInitiated = createAction('[Currency Rates API] Currency rates retrieve initiated');

export const getCurrencyRatesSuccess = createAction(
  '[Currency Rates API]  Currency rates retrieve success',
  props<{ payload: ICurrencyRateModel[] }>()
);

export const getCurrencyRatesFailed = createAction(
  '[Currency Rates API]  Currency rates retrieve failed',
  props<{ payload: IApiErrorModel }>()
);

export const convertCurrencyActionInitiated = createAction(
  '[Currency Rates] Convert Currency initiated',
  props<{ payload: IConvertCurrencyActionModel }>()
);

export const CurrencyRatesActions = {
  getCurrencyRatesInitiated,
  getCurrencyRatesSuccess,
  getCurrencyRatesFailed,
  convertCurrencyActionInitiated,
};
