import { createAction, props } from '@ngrx/store';

import { IApiErrorModel } from '@shared/models';
import { IHomeResponseModel } from '@features/home/models';

export const getHomeDataInitiated = createAction('[Home API] Home data retrieve initiated');

export const getHomeDataSuccess = createAction(
  '[Home API] Home data retrieve success',
  props<{ payload: IHomeResponseModel }>()
);

export const getHomeDataFailed = createAction(
  '[Home API] Home data retrieve failed',
  props<{ payload: IApiErrorModel }>()
);

export const changeLiveDealsActiveStatus = createAction(
  '[Home API] Change live deals active status',
  props<{ payload: string }>()
);

export const HomeActions = {
  getHomeDataInitiated,
  getHomeDataSuccess,
  getHomeDataFailed,
  changeLiveDealsActiveStatus,
};
