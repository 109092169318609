import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { CloseButtonSizeEnum } from './close-button-size.enum';

@Component({
  selector: 'close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseButtonComponent implements OnChanges {
  @Input()
  public size: CloseButtonSizeEnum = CloseButtonSizeEnum.MD;

  @Output()
  public clickButton: EventEmitter<void> = new EventEmitter();

  public CloseButtonSizeEnum = CloseButtonSizeEnum;
  public iconSize = 14;

  public ngOnChanges(): void {
    this.iconSize = this.getIconSize();
  }

  public onButtonClick() {
    this.clickButton.emit();
  }

  private getIconSize(): number {
    switch (this.size) {
      case CloseButtonSizeEnum.SM:
        return 8;
      default:
        return 14;
    }
  }
}
