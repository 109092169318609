import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const PROD_URL = 'app.fundre.co';
const AG_GRID_LICENSE =
  'Using_this_AG_Grid_Enterprise_key_( AG-047704 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( FundRE Limited )_is_granted_a_( Single Application )_Developer_License_for_the_application_( FundRE Limited )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( FundRE Limited )_need_to_be_licensed___( FundRE Limited )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 15 September 2024 )____[v2]_MTcyNjM1NDgwMDAwMA==036086b30156c93b9f701f46172db0ca';

// Avoid non prod environments to be tracked by Plausible
if (window.location.host !== PROD_URL) {
  window.localStorage.plausible_ignore = true;
}

if (environment.isProdMode) {
  enableProdMode();
}

LicenseManager.setLicenseKey(AG_GRID_LICENSE);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
