export enum ButtonColorsEnum {
  PRIMARY_LIGHT = 'primary-light',
  PRIMARY_DARK = 'primary-dark',
  SECONDARY_LIGHT = 'secondary-light',
  SECONDARY_DARK = 'secondary-dark',
  SECONDARY_GRAY = 'secondary-gray',
  SECONDARY_GRAY_TEXT = 'secondary-gray-text',
  ALTERNATIVE_LIGHT = 'alternative-light',
  LINK = 'link',
}
