import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  public errorMessage$: Observable<string>;

  private errorMessageSubj$: BehaviorSubject<string | null> = new BehaviorSubject(null);

  constructor() {
    this.errorMessage$ = this.errorMessageSubj$.asObservable();
  }

  public newError(message: string): void {
    this.errorMessageSubj$.next(message);
  }

  public hide(): void {
    this.errorMessageSubj$.next(null);
  }
}
