import * as XLSX from 'xlsx';
import { ISheetPagesModel } from '@shared/models';

export function exportToExcelUtils(sheets: ISheetPagesModel[], fileName) {
  const wb: XLSX.WorkBook = XLSX.utils.book_new();

  sheets.forEach((sheet) => {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheet.data);

    /* generate workbook and add the worksheet */

    XLSX.utils.book_append_sheet(wb, ws, sheet.sheetName);
  });

  /* save to file */
  XLSX.writeFile(wb, fileName);
}
