export enum CriteriaViewModeEnum {
  CREATE = 'create',
  EDIT = 'edit',
  READ = 'read',
  INVESTMENT_READ = 'readInvestmentCriteria',
  LENDING_READ = 'readLendingCriteria',
  INVESTMENT_CREATE = 'createInvestmentCriteria',
  LENDING_CREATE = 'createLendingCriteria',
  INVESTMENT_EDIT = 'editInvestmentCriteria',
  LENDING_EDIT = 'editLendingCriteria',
}
