import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { EQUAL_FORM_ERROR, GREATER_FORM_ERROR } from '@shared/constants';

export const greaterValueValidator =
  (targetKey: string, toMatchKey: string, nestedGroupKey?: string): ValidatorFn =>
  (group: UntypedFormGroup): ValidationErrors | null => {
    const target: any = nestedGroupKey
      ? (group.controls[targetKey] as UntypedFormGroup).controls[nestedGroupKey]
      : group.controls[targetKey];
    const toMatch: any = nestedGroupKey
      ? (group.controls[toMatchKey] as UntypedFormGroup).controls[nestedGroupKey]
      : group.controls[toMatchKey];

    if (isEmptyInputValue(target.value) || isEmptyInputValue(toMatch.value)) {
      return null;
    }

    return isNaN(toMatch.value) || target.value >= toMatch.value ? null : { [GREATER_FORM_ERROR]: true };
  };

export const equalValueValidator =
  (targetKey: string, toMatchKey: string, nestedGroupKey?: string): ValidatorFn =>
  (group: UntypedFormGroup): ValidationErrors | null => {
    const target: any = nestedGroupKey
      ? (group.controls[targetKey] as UntypedFormGroup).controls[nestedGroupKey]
      : group.controls[targetKey];
    const toMatch: any = nestedGroupKey
      ? (group.controls[toMatchKey] as UntypedFormGroup).controls[nestedGroupKey]
      : group.controls[targetKey];

    if (isEmptyInputValue(target.value) || isEmptyInputValue(toMatch.value)) {
      return null;
    }

    return isNaN(toMatch.value) || target.value === toMatch.value ? null : { [EQUAL_FORM_ERROR]: true };
  };

const isEmptyInputValue = (value: any): boolean => value == null || value.length === 0;
