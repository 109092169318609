export enum CriteriaPropertyEnum {
  ID = 'id',
  NAME = 'name',
  COUNTRIES = 'countries',
  ASSET_CLASSES = 'assetClasses',
  STRATEGIES = 'strategies',
  LOAN_TYPE = 'loanType',
  PURPOSES = 'purposes',
  INCLUDE_CLUB_DEALS = 'includeClubDeals',
  INCLUDE_JOINT_VENTURE = 'includeJointVenture',
  TYPICAL_LEVERAGE_POINT_PERCENT = 'typicalLeveragePointPercent',
  TYPICAL_TRANSACTION_SIZE = 'typicalTransactionSize',
  MINIMUM_EQUITY_AMOUNT = 'minimumEquityAmount',
  MAXIMUM_EQUITY_AMOUNT = 'maximumEquityAmount',
  MAXIMUM_MATURITY = 'maximumMaturity',
  EQUITY_TYPE = 'equityType',
  MINIMUM_LOAN_AMOUNT = 'minimumLoanAmount',
  MAXIMUM_LOAN_AMOUNT = 'maximumLoanAmount',
  MINIMUM_RETURN_PERCENT = 'minimumReturnPercent',
  MINIMUM_RETURN_PERCENT_TYPE = 'minimumReturnPercentType',
  MINIMUM_RETURN_TYPE = 'minimumReturnType',
  MAXIMUM_LEVERAGE_POINT = 'maximumLeveragePoint',
  MATURITY = 'maturity',
  NOTES = 'notes',
  MANDATE_TYPE = 'mandateType',
}
