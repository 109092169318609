import { AreaUnitEnum } from '@shared/enums';

export const AREA_UNITS = [
  {
    key: AreaUnitEnum.SQFT,
    value: 'sq ft',
  },
  {
    key: AreaUnitEnum.SQM,
    value: 'sqm',
  },
];

export const AREA_UNITS_MAP = AREA_UNITS.reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});
