import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloseButtonComponent } from '@shared/components/close-button/close-button.component';
import { IconModule } from '@shared/components/icon';

@NgModule({
  declarations: [CloseButtonComponent],
  exports: [CloseButtonComponent],
  imports: [CommonModule, IconModule],
})
export class CloseButtonModule {}
