import { AccountTypeEnum } from '@shared/enums';

export const ACCOUNT_TYPES = [
  {
    key: AccountTypeEnum.BORROWER,
    value: AccountTypeEnum.BORROWER,
  },
  {
    key: AccountTypeEnum.LENDER,
    value: AccountTypeEnum.LENDER,
  },
  {
    key: AccountTypeEnum.BROKER,
    value: 'Debt Advisor',
  },
];

export const ACCOUNT_TYPES_LABEL = ACCOUNT_TYPES.reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});
