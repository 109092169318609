import { createAction, props } from '@ngrx/store';

export const errorOccurred = createAction('[Generic] Error occurred', props<{ payload }>());

export const clearErrors = createAction('[Generic] Clear errors');

export const ErrorHandlerActions = {
  errorOccurred,
  clearErrors,
};
