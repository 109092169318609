import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IAppState } from '@shared/models';
import { NavigationPageActions } from '@features/navigation/state/actions';
import { INavigationLinkModel, IUserSidebarInfoModel } from '@features/navigation/models';
import {
  getIsNavigationCollapsed,
  getProfileLink,
  getSidebarAccountType,
  getSideMenuLinks,
  getProfileSidebarInfo,
  getCurrentNavItem,
} from '@features/navigation/state/selectors';
import { UserActions } from '@features/auth';
import { AccountTypeEnum } from '@shared/enums';
import { SidebarAdditionalAccountTypesEnum } from '@features/navigation/enums';

@Component({
  selector: 'navigation-container',
  templateUrl: './navigation-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationContainerComponent implements OnInit, AfterViewInit {
  public sideMenuLinks$: Observable<INavigationLinkModel[]>;
  public profileLink$: Observable<INavigationLinkModel>;
  public profileInfoObs$: Observable<IUserSidebarInfoModel>;
  public isNavigationCollapsed$: Observable<boolean>;
  public currentNavigationItem$: Observable<string>;
  public accountType$: Observable<AccountTypeEnum | SidebarAdditionalAccountTypesEnum>;
  public profileInfo: IUserSidebarInfoModel;

  constructor(private store$: Store<IAppState>, private cdRef: ChangeDetectorRef) {}

  public ngOnInit() {
    this.profileLink$ = this.store$.pipe(select(getProfileLink));
    this.profileInfoObs$ = this.store$.pipe(select(getProfileSidebarInfo));
    this.isNavigationCollapsed$ = this.store$.pipe(select(getIsNavigationCollapsed));
    this.accountType$ = this.store$.pipe(select(getSidebarAccountType));
    this.currentNavigationItem$ = this.store$.pipe(select(getCurrentNavItem));
    this.sideMenuLinks$ = this.store$.pipe(select(getSideMenuLinks));

    this.profileInfoObs$.subscribe((info) => {
      this.profileInfo = info;
      this.cdRef.detectChanges();
    });
  }
  public ngAfterViewInit(): void {
    this.sideMenuLinks$ = this.store$.pipe(select(getSideMenuLinks));
  }
  public onNavigationToggled() {
    this.store$.dispatch(NavigationPageActions.navigationToggled());
  }

  public onLogout() {
    this.store$.dispatch(UserActions.logoutInitiated());
  }

  public onSwitchAccount() {
    this.store$.dispatch(NavigationPageActions.switchAccountTypeInitiated());
  }

  public onUserGuideClick() {
    this.store$.dispatch(NavigationPageActions.userGuideClick());
  }

  public onNavigationItemChanged(itemRoute: string) {
    this.store$.dispatch(NavigationPageActions.currentItemChanged({ payload: itemRoute }));
  }
}
