import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import { environment } from '@environment';
import { CognitoUserService } from '@features/auth/services';
import { UserActions } from '@features/auth/state/actions';
import { SpinnerCoverService } from '@features/spinner';
import { IAppState } from '@shared/models';
import { createAuthTokenHeader } from '@shared/utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store$: Store<IAppState>,
    private spinnerService: SpinnerCoverService,
    private cognitoUserService: CognitoUserService
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes(environment.apiUrl)) {
      return next.handle(request);
    }

    return this.cognitoUserService.getIdToken().pipe(
      take(1),
      switchMap((idToken) => {
        if (idToken) {
          request = request.clone({ setHeaders: createAuthTokenHeader(idToken) });
        }

        return next.handle(request).pipe(catchError(this.handleAuthError.bind(this)));
      })
    );
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      this.spinnerService.end();
      this.store$.dispatch(UserActions.invalidTokenDetected());
      // Error is already handled so we can just `complete`
      return EMPTY;
    }
    return throwError(err);
  }
}
