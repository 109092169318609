import { createSelector } from '@ngrx/store';

import { checkIsUserAdmin } from '@features/auth/utils/roles.utils';
import { AccountTypeEnum, CurrencyEnum } from '@shared/enums';

import { getUserState } from './auth-base.selectors';

export const getCurrentStorageUser = createSelector(getUserState, (userState) => userState.currentStorageUser);

export const getCurrentUser = createSelector(getUserState, (userState) => userState.currentUser);

export const getCurrentUserPreferences = createSelector(
  getUserState,
  (userState) => userState.currentUser?.preferences
);

export const getCurrentUserId = createSelector(getCurrentUser, (currentUser) => currentUser?.id);

export const getCurrentUserRole = createSelector(getCurrentUser, (currentUser) => currentUser?.role);

// Be careful with this selector, because user state initialize later. Use in combination with waitUntilUserInitialized util in effects.
export const getCurrentAccountType = createSelector(getUserState, (userState) => userState.accountType);

export const getIsLender = createSelector(
  getCurrentAccountType,
  (accountType) => accountType === AccountTypeEnum.LENDER
);

export const getIsBorrower = createSelector(
  getCurrentAccountType,
  (accountType) => accountType === AccountTypeEnum.BORROWER || accountType === AccountTypeEnum.BROKER
);

export const getIsDebtAdvisor = createSelector(
  getCurrentAccountType,
  (accountType) => accountType === AccountTypeEnum.BROKER
);

export const getCurrentTenant = createSelector(getUserState, (userState) => userState.currentTenant);

export const getCurrentTenantId = createSelector(getUserState, (userState) => userState.currentTenant?.id);

export const getCurrentTenantName = createSelector(getCurrentTenant, (currentTenant) => currentTenant?.name);

export const getCurrentTenantCurrency = createSelector(
  getCurrentTenant,
  (currentTenant) => currentTenant?.defaultCurrency || CurrencyEnum.EUR
);

export const getIsUserStateInitialized = createSelector(getUserState, (userState) => userState.isUserStateInitialized);

export const getIsUserVerified = createSelector(
  getCurrentStorageUser,
  (storageUser) => storageUser && storageUser.isEmailVerified
);

// Avoids returning false when user is not ready
export const getIsUserAdmin = createSelector(getCurrentUser, (user) => (user !== null ? checkIsUserAdmin(user) : null));

export const getPrimaryAccountType = createSelector(
  getCurrentTenant,
  (currentTenant) => currentTenant?.primaryAccountType
);
