import { Action, createReducer, on } from '@ngrx/store';
import Romit from 'ramda/es/omit';

import { IUserStateModel } from '@features/auth/models';
import { ProfileActions, UserActions } from '@features/auth/state/actions';

export const initialUserState: IUserStateModel = {
  currentStorageUser: null,
  currentUser: null,
  isUserStateInitialized: false,
  currentTenant: null,
  accountType: null,
};

const reducer = createReducer(
  initialUserState,
  on(UserActions.logoutInitiated, UserActions.invalidTokenDetected, () => initialUserState),
  on(UserActions.storageUserChanged, (state, { payload }) => ({
    ...state,
    currentStorageUser: payload,
  })),
  on(UserActions.apiUserChanged, (state, { payload }) => ({
    ...state,
    currentUser: Romit(['tenant'], payload),
    currentTenant: payload.tenant,
    isUserStateInitialized: true,
    accountType: state.accountType || payload.tenant.primaryAccountType,
  })),
  on(ProfileActions.accountTypeChanged, ProfileActions.accountTypeInitiated, (state, { payload }) => ({
    ...state,
    accountType: payload,
  }))
);

export function userReducer(state: IUserStateModel | undefined, action: Action): IUserStateModel {
  return reducer(state, action);
}
