export enum FiltersKeyLabelEnum {
  statuses = 'Status',
  countries = 'Geography',
  assetTypes = 'Asset Type',
  loanTypes = 'Loan Type',
  mandateTypes = 'Mandate Type',
  strategies = 'Strategies',
  dates = 'Closing dates',
  companyTypes = 'Company Type',
  mandateCountries = 'Geography',
  mandateAssetTypes = 'Asset Type',
  mandateStrategies = 'Strategies',
  mandateLoanTypes = 'Loan Type',
}
