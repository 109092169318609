import { Injectable } from '@angular/core';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { environment } from '@environment';
import { IUserStorageModel } from '@features/auth/models';
import { LocalStorageService } from '@shared/services';
import { EXPIRE_HOURS } from '@features/auth/constants';

@Injectable({
  providedIn: 'root',
})
// All of the functionality in this service can be accomplished by using `aws-amplify` package
// But that package is quite big so we want to omit loading it on start
// Instead we limit using of that library to lazy loaded modules
// And for app start functionality (getting of userData, tokens etc.) we use this service
// Logic here is added based on `aws-amplify` internal code
export class CognitoUserService {
  private cognitoUser: CognitoUser;
  private userPool: CognitoUserPool;

  public get initializedCognitoUser(): CognitoUser {
    if (!this.cognitoUser) {
      this.initCognitoProps();
    }

    return this.cognitoUser;
  }

  public get initializedUserPool(): CognitoUserPool {
    if (!this.userPool) {
      this.initCognitoProps();
    }

    return this.userPool;
  }

  constructor(private localStorageService: LocalStorageService) {}

  public getStorageUserData(): IUserStorageModel {
    if (!this.checkExpiry()) {
      return;
    }

    const cognitoUserStoragePrefix = this.getUserStoragePrefix();
    const userDataFromStorage = this.localStorageService.getItem(`${cognitoUserStoragePrefix}.userData`, true);

    return this.mapUserData(userDataFromStorage?.UserAttributes);
  }

  public getIdToken(): Observable<string> {
    if (!this.checkExpiry()) {
      return of(null);
    }

    return this.requestCurrentSession()
      .pipe(map((session) => session?.idToken?.jwtToken))
      .pipe(
        catchError(() => of(null)),
        take(1),
        map((idToken) => idToken || this.getStorageTokens()?.id)
      );
  }

  public signOut(): void {
    if (this.initializedCognitoUser) {
      this.localStorageService.removeItem(this.getExpiryStorageName());
      this.initializedCognitoUser.signOut();
      this.clearCognitoProps();
    }
  }

  public initCognitoProps(): void {
    try {
      const currentUsername = this.getUserStorageUsername();
      if (currentUsername) {
        this.userPool = new CognitoUserPool({
          UserPoolId: environment.awsAmplifyConfig.userPoolId,
          ClientId: environment.awsAmplifyConfig.userPoolWebClientId,
        });
        this.cognitoUser = new CognitoUser({
          Username: currentUsername,
          Pool: this.userPool,
        });
      }
    } catch {
      this.clearCognitoProps();
    }
  }

  public updateExpiry(): void {
    const expiryStorageName = this.getExpiryStorageName();
    const newValue = new Date();
    newValue.setHours(newValue.getHours() + EXPIRE_HOURS);
    this.localStorageService.setItem(expiryStorageName, newValue.getTime());
  }

  public checkExpiry(): boolean {
    const storageValue = +this.localStorageService.getItem(this.getExpiryStorageName());

    if (!storageValue || storageValue > new Date().getTime()) {
      this.updateExpiry();
      return true;
    }

    return false;
  }

  private getExpiryStorageName(): string {
    return `${this.getUserStoragePrefix()}.expiresAt`;
  }

  private getUserStorageCognitoPrefix() {
    return `CognitoIdentityServiceProvider.${environment.awsAmplifyConfig.userPoolWebClientId}`;
  }

  private getUserStorageUsername() {
    const lastUserStorageKey = `${this.getUserStorageCognitoPrefix()}.LastAuthUser`;
    return this.localStorageService.getItem(lastUserStorageKey);
  }

  private getUserStoragePrefix(): string {
    const currentUsername = this.getUserStorageUsername();
    return currentUsername && `${this.getUserStorageCognitoPrefix()}.${currentUsername}`;
  }

  private clearCognitoProps(): void {
    this.cognitoUser = null;
    this.userPool = null;
  }

  private requestCurrentSession(): Observable<any> {
    return new Observable((subscriber) => {
      try {
        const currentSession = this.getUserSession();

        if (!currentSession) {
          subscriber.error();
        }

        if (currentSession?.isValid()) {
          subscriber.next(currentSession);
          subscriber.complete();
        }

        if (!this.initializedCognitoUser) {
          subscriber.error();
        } else {
          this.initializedCognitoUser.getSession((err, val) => {
            if (err) {
              subscriber.error();
            } else {
              subscriber.next(val);
              subscriber.complete();
            }
          });
        }
      } catch {
        subscriber.error();
      }
    });
  }

  private mapUserData(userAttributes): IUserStorageModel {
    if (!userAttributes) {
      return null;
    }

    const convertedUserAttributes = userAttributes.reduce((acc, item) => {
      acc[item.Name] = item.Value;

      return acc;
    }, {});

    return {
      id: convertedUserAttributes.sub,
      email: convertedUserAttributes.email,
      isEmailVerified: convertedUserAttributes.email_verified,
    };
  }

  private getUserSession(): CognitoUserSession {
    const tokens = this.getStorageTokens();

    if (!tokens) {
      return;
    }

    const idToken = new CognitoIdToken({ IdToken: tokens.id });
    const accessToken = new CognitoAccessToken({ AccessToken: tokens.access });
    const refreshToken = new CognitoRefreshToken({ RefreshToken: tokens.refresh });
    const clockDrift = parseInt(tokens.clockDrift) || 0;

    const sessionData = {
      IdToken: idToken,
      AccessToken: accessToken,
      RefreshToken: refreshToken,
      ClockDrift: clockDrift,
    };
    return new CognitoUserSession(sessionData);
  }

  private getStorageTokens() {
    const cognitoUserStoragePrefix = this.getUserStoragePrefix();
    return (
      cognitoUserStoragePrefix && {
        id: this.localStorageService.getItem(`${cognitoUserStoragePrefix}.idToken`),
        access: this.localStorageService.getItem(`${cognitoUserStoragePrefix}.accessToken`),
        refresh: this.localStorageService.getItem(`${cognitoUserStoragePrefix}.refreshToken`),
        clockDrift: this.localStorageService.getItem(`${cognitoUserStoragePrefix}.clockDrift`),
      }
    );
  }
}
