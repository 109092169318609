import { CountriesEnum } from '@shared/enums';

export const COUNTRIES: { key: string; value: any }[] = Object.values(CountriesEnum).map((country) => ({
  key: country,
  value: country,
}));

export const MIXED_COUNTRIES_NAME = 'Pan-European';
export const MIXED_COUNTRIES_VALUE = `${MIXED_COUNTRIES_NAME} Portfolios`;

export const COUNTRIES_WITH_MIXED = [
  {
    key: MIXED_COUNTRIES_NAME,
    value: MIXED_COUNTRIES_VALUE,
  },
  ...COUNTRIES,
];
