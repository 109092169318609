import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ButtonColorsEnum } from '@shared/enums';
import { ModalOverlayService } from '@features/overlay/services';

@Component({
  selector: 'confirmation-overlay',
  templateUrl: './confirmation-overlay.component.html',
  styleUrls: ['./confirmation-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationOverlayComponent {
  @Input()
  public title: string;

  public ButtonColorsEnum = ButtonColorsEnum;

  constructor(private overlayService: ModalOverlayService) {}

  public onConfirm(): void {
    this.overlayService.closeModal(true);
  }

  public onCancel(): void {
    this.overlayService.closeModal(false);
  }

  public onCloseModal(): void {
    this.overlayService.closeModal(null);
  }

  public onClickOutside(): void {
    this.overlayService.closeModal(null);
  }
}
