import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ICurrencyRatesFeatureStateModel } from '@features/currency-rates/models';

export const currencyRatesFeatureName = 'currencyRates';

export const getCurrencyRatesFeatureState =
  createFeatureSelector<ICurrencyRatesFeatureStateModel>(currencyRatesFeatureName);

export const getCurrencyRatesState = createSelector(getCurrencyRatesFeatureState, (state) => state.currencyRates);
