import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'switch-overlay',
  templateUrl: './switch-overlay.component.html',
  styleUrls: ['./switch-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchOverlayComponent {
  @Output()
  public confirm: EventEmitter<any> = new EventEmitter();

  @Input()
  public activeTab: any;

  @Input()
  public tabs: any[];

  @Input()
  public title: string;

  @Input()
  public buttonText: string;

  public onConfirm() {
    this.confirm.emit(this.activeTab);
  }

  public onCloseModal() {
    this.confirm.emit(null);
  }

  public setActiveTab(value: any) {
    this.activeTab = value;
  }

  public onClickOutside() {
    this.confirm.emit(null);
  }
}
