import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { InitActions } from '@app/shared/state/actions';

@Directive({
  selector: '[scrollDirective]',
})
export class ScrollDirective implements OnInit, OnDestroy {
  public innerScrollElement: any;

  @Input() public set scrollDirective(element: any) {
    this.innerScrollElement = element;
  }

  @Output() private scrolledToPoint: EventEmitter<void> = new EventEmitter<void>();

  public lastScroll = 0;
  private innerElementLastHeight = 0;

  private destroy$ = new Subject<void>();

  constructor(private router: Router, private actions$: Actions) {
    // Scroll to top when profile page has initialized. Otherwise page keeps profile-search scroll.
    this.actions$.pipe(ofType(InitActions.profileSinglePageInitialized)).subscribe(() => {
      this.innerScrollElement.scrollTo(0, 0);
    });
  }

  @HostListener('scroll', ['$event'])
  public onListenerTriggered(event): void {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const percent = Math.round((scrollTop / (scrollHeight - clientHeight)) * 100);

    // If the inner element height changes (the element hosting page content) means its content has changed
    // so we need to fire event again when reaching bottom.
    if (this.innerElementLastHeight !== this.innerScrollElement.clientHeight) {
      this.innerElementLastHeight = this.innerScrollElement.clientHeight;
      this.lastScroll = 0;
    }

    if (percent >= 80 && this.lastScroll < scrollTop) {
      this.lastScroll = scrollTop;
      this.scrolledToPoint.emit();
    }
  }

  public ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.lastScroll = 0;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
