export enum CountriesEnum {
  AUSTRIA = 'Austria',
  BELGIUM = 'Belgium',
  CZECH_REPUBLIC = 'Czech Republic',
  DENMARK = 'Denmark',
  FINLAND = 'Finland',
  FRANCE = 'France',
  GERMANY = 'Germany',
  GREECE = 'Greece',
  IRELAND = 'Ireland',
  ITALY = 'Italy',
  LUXEMBOURG = 'Luxembourg',
  NETHERLANDS = 'Netherlands',
  NORWAY = 'Norway',
  PORTUGAL = 'Portugal',
  POLAND = 'Poland',
  SLOVAKIA = 'Slovakia',
  SPAIN = 'Spain',
  SWEDEN = 'Sweden',
  SWITZERLAND = 'Switzerland',
  UNITED_KINGDOM = 'United Kingdom',
}
