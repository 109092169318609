<a
  class="navigation-item__container"
  [routerLink]="link.route"
  [class.navigation-item__container--active]="isLinkActive"
  [class.navigation-item__container--collapsed]="isNavigationCollapsed"
  [fr-tooltip]="link.name"
  [fr-tooltip-hidden]="!isNavigationCollapsed"
  [fr-tooltip-is-menu]='true'
  (click)="onNavigationSelected(link)"
>
  <app-icon
    class="navigation-item__icon"
    size="20"
    [svgPath]="link.icon"
  ></app-icon>
  <span
    class="navigation-item__text"
    [class.navigation-item__text--collapsed]="isNavigationCollapsed"
  >{{link.name}}</span>
</a>
