import { isChoosedDateLessThenCurrent } from '@shared/utils';
import { NUMBER_OF_DAYS_FOR_RESEND, NUMBER_OF_DAYS_FOR_RESEND_TO_PLATFORM } from '@features/profile-contact/constants';

export const mapContacts = (contacts) =>
  contacts.map((contact) => ({
    ...contact,
    canResendInviteTeam: contact.requestDate
      ? isChoosedDateLessThenCurrent(contact.requestDate, NUMBER_OF_DAYS_FOR_RESEND)
      : true,
    canResendInvitePlatformDate: contact.requestToPlatformDate
      ? isChoosedDateLessThenCurrent(contact.requestToPlatformDate, NUMBER_OF_DAYS_FOR_RESEND_TO_PLATFORM)
      : true,
  }));
