import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { ReactiveFormsModule } from '@angular/forms';

import { environment } from '@environment';
import { AuthModule } from '@features/auth';
import { SpinnerModule } from '@features/spinner';
import { RouterSerializer } from '@shared/serializers';
import { CurrencyRatesModule } from '@features/currency-rates';
import { appMetaReducers } from '@shared/state/meta-reducers';
import { appReducers } from '@shared/state/reducers';
import { CacheInterceptor, ErrorInterceptor } from '@shared/interceptors';

import { MainContentModule } from './components/main-content';
import { AppComponent } from './app.component';
import { PreloadSelectedModulesList, routes } from './app.routes';
import { NewsFeatureModule } from './features/news/news.module';

const IS_RUNTIME_CHECKS_ACTIVE = !environment.isProdMode;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadSelectedModulesList,
      paramsInheritanceStrategy: 'always',
    }),
    StoreModule.forRoot(appReducers, {
      metaReducers: appMetaReducers,
      runtimeChecks: {
        strictStateImmutability: IS_RUNTIME_CHECKS_ACTIVE,
        strictActionImmutability: IS_RUNTIME_CHECKS_ACTIVE,
        strictStateSerializability: IS_RUNTIME_CHECKS_ACTIVE,
        strictActionWithinNgZone: IS_RUNTIME_CHECKS_ACTIVE,
        strictActionTypeUniqueness: IS_RUNTIME_CHECKS_ACTIVE,
        strictActionSerializability: false,
      },
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      serializer: RouterSerializer,
    }),
    AuthModule.forRoot(),
    MainContentModule,
    SpinnerModule,
    OverlayModule,
    CurrencyRatesModule,
    ReactiveFormsModule,
    NewsFeatureModule,
  ],
  providers: [
    PreloadSelectedModulesList,
    // IE11 cached GET request, so we added interceptor that set headerr: no-cache
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
