import { DataRoomDocumentTypesEnum } from '@app/features/deals-data-room/enums';
import { AccountTypeEnum } from '../enums';

export const mapDataRoomSharedFolders = (
  accountType: AccountTypeEnum,
  fullDataRoomFolderList,
  userSharedFoldersList: DataRoomDocumentTypesEnum[]
) =>
  accountType === AccountTypeEnum.LENDER
    ? fullDataRoomFolderList.filter((item) => userSharedFoldersList.includes(item.key))
    : fullDataRoomFolderList;
