import { DatePipe } from '@angular/common';
import { STANDARD_DISPLAY_DATE } from '../constants';

export const startOfDay = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const isChoosedDateLessThenCurrent = (date: string, numberOfDays: number = 0): boolean => {
  if (!date) return true;

  const currentDate = addDaysToDate(new Date(date), numberOfDays);

  return currentDate.getTime() < new Date().getTime();
};

export const addDaysToDate = (currentDate: Date, numberOfDays: number): Date =>
  new Date(currentDate.setDate(currentDate.getDate() + numberOfDays));

export const parseToSystemDate = (date: Date, yearFirst = false) => {
  // TODO: Replace to date.toLocaleString('en-us', { dateStyle: "short" }) once upgrading to Typescript >= v4.2.3
  // There is currently an issue in Typescript that dones't allow to use dateStyle option. (yes)
  // https://github.com/microsoft/TypeScript/pull/41880

  // Also, we need to standardize dates in the whole app
  // https://fundre.atlassian.net/browse/FRE-285

  const dateFirstPart = yearFirst ? date.getFullYear() : date.getDate();
  const dateLastPart = yearFirst ? date.getDate() : date.getFullYear();

  // Month is expresed from 0 to 11.
  return `${dateFirstPart}/${date.getMonth() + 1}/${dateLastPart}`;
};

/**
 * Transforms a given date into the date format we use in the UI using the Angular date pipe
 * @param date
 * @returns date in our UI format
 */
export const transformToUIDate = (date: Date | string) => new DatePipe('en-US').transform(date, STANDARD_DISPLAY_DATE);
