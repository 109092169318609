import { createAction, props } from '@ngrx/store';

import { IUserResponseModel, IUserStorageModel } from '@features/auth/models';

export const storageUserChanged = createAction(
  '[App Auth] Storage user changed',
  props<{ payload: IUserStorageModel }>()
);

export const apiUserChanged = createAction('[App Auth] Api user changed', props<{ payload: IUserResponseModel }>());

export const logoutInitiated = createAction('[App Auth] Logout initiated');

export const invalidTokenDetected = createAction('[App Auth] Invalid token detected');

export const retrieveSystemNewsInitiated = createAction('[App Auth] Get system news initiated');

export const UserActions = {
  storageUserChanged,
  logoutInitiated,
  apiUserChanged,
  invalidTokenDetected,
  retrieveSystemNewsInitiated,
};
