export enum DealsScopeEnum {
  MY_CLOSED = 'My Closed',
  INVOLVED = 'Involved',
  ARCHIVED = 'Archived',
}

export enum DealsTextEnum {
  MY_CLOSED = 'My closed deals',
  INVOLVED = 'Involved',
  ARCHIVED = 'Archived',
}
