import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { IconModule } from '@shared/components/icon';
import { ButtonModule } from '@features/forms/button';
import { OverlayModule } from '@features/overlay';
import { CloseButtonModule } from '@shared/components/close-button';
import { TooltipModule } from '@shared/directives/tooltip';
import { AvatarModule } from '@shared/components/avatar';
import { NavigationEffects } from './state/effects';
import { navigationFeatureName } from './state/selectors';
import { navigationFeatureReducers } from './state/reducers';
import {
  NavigationComponent,
  NavigationContainerComponent,
  NavigationItemComponent,
  SwitchAccountTypeComponent,
  SwitchAccountTypeContainerComponent,
} from './components';
import { AnimatedIconsModule } from '@app/shared/animated-icons/animated-icons.module';

@NgModule({
  declarations: [
    NavigationContainerComponent,
    NavigationComponent,
    NavigationItemComponent,
    SwitchAccountTypeComponent,
    SwitchAccountTypeContainerComponent,
  ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([NavigationEffects]),
    StoreModule.forFeature(navigationFeatureName, navigationFeatureReducers),
    IconModule,
    RouterModule,
    CloseButtonModule,
    ButtonModule,
    OverlayModule,
    TooltipModule,
    AvatarModule,
    AnimatedIconsModule,
  ],
  exports: [NavigationContainerComponent],
})
export class NavigationModule {}
