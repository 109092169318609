import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { STRICT_MAX } from '@shared/constants';

export const strictMaxValidator =
  (max: number): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
      return null;
    }
    const value = parseFloat(control.value);

    return !isNaN(value) && value >= max ? { [STRICT_MAX]: { [STRICT_MAX]: max, actual: control.value } } : null;
  };

const isEmptyInputValue = (value: any): boolean => value == null || value.length === 0;
