import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { ButtonColorsEnum } from '@shared/enums';
import { ModalOverlayService } from '@features/overlay/services';

@Component({
  selector: 'information-overlay',
  templateUrl: './information-overlay.component.html',
  styleUrls: ['./information-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationOverlayComponent {
  @Input()
  public title: string;

  public ButtonColorsEnum = ButtonColorsEnum;

  constructor(private overlayService: ModalOverlayService) {}

  public onCloseModal(): void {
    this.overlayService.closeModal(null);
  }

  public onClickOutside(): void {
    this.overlayService.closeModal(null);
  }
}
