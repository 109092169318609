<button
  class="fr-btn"
  [ngClass]="{
    'fr-btn--primary-light': color === ButtonColorsEnum.PRIMARY_LIGHT,
    'fr-btn--primary-dark': color === ButtonColorsEnum.PRIMARY_DARK,
    'fr-btn--secondary-light': color === ButtonColorsEnum.SECONDARY_LIGHT,
    'fr-btn--secondary-dark': color === ButtonColorsEnum.SECONDARY_DARK,
    'fr-btn--secondary-gray': color === ButtonColorsEnum.SECONDARY_GRAY,
    'fr-btn--secondary-gray-text': color === ButtonColorsEnum.SECONDARY_GRAY_TEXT,
    'fr-btn--alternative-light': color === ButtonColorsEnum.ALTERNATIVE_LIGHT,
    'fr-btn--link': color === ButtonColorsEnum.LINK,
    'fr-btn--no-shadow': noShadow,
    'fr-btn--icon-only': icon && isIconOnly,
    'fr-btn--icon-with-border-only': icon && isIconWithBorderOnly,
    'fr-btn--align-right': alignContentRight,
    'fr-btn--without-icon':withoutIcon,
    'fr-btn--small': size === 'small',
    'fr-btn--regular': size === 'regular'
  }"
  [type]="type"
  [disabled]="disabled"
  (click)="onClick($event)"
  [fr-tooltip]="tooltipText"
  [fr-tooltip-hidden]="!tooltipText || !(isIconOnly || isIconWithBorderOnly)"
  [fr-tooltip-position]="'bottom'"
  [fr-tooltip-offsetY]="2"
>
  <ng-content *ngIf="!isIconWithBorderOnly"></ng-content>
  <span
    *ngIf="icon"
    [ngClass]="{
      'fr-btn__icon-container': !isIconOnly,
      'fr-btn__icon-only-container': isIconOnly,
      'fr-btn__icon-with-border-only-container': isIconWithBorderOnly
    }"
  >
    <app-icon [svgPath]="icon" [size]="iconSize" class="fr-btn__icon"></app-icon>
  </span>
</button>
