export enum AppRoutesEnum {
  DEFAULT = 'home',

  // ROOT pages
  AUTH = 'auth',
  SYSTEM = 'system',
  HOME = 'home',
  MANAGEMENT = 'management',
  DEALS = 'deals',
  DEAL_SEARCH = 'deal-search',
  PROFILE_SEARCH = 'profile-search',
  MARKET_DATA = 'market-data',
  PROFILE_SETTINGS = 'profile-settings',

  // ROOT->AUTH sub pages
  AUTH_LOGIN = 'login',
  AUTH_SIGN_UP = 'sign-up',
  AUTH_FORGOT_PASSWORD = 'forgot-password',
  AUTH_FORGOT_PASSWORD_RESET = 'forgot-password-reset',
  AUTH_EMAIL_CONFIRMATION = 'email-confirmation',

  // ROOT->SYSTEM sub pages
  SYSTEM_TENANTS = 'tenants',
  SYSTEM_ADMINS = 'super-admins',
  SYSTEM_DEALS = 'deals',
  SYSTEM_CLOSED_DEALS = 'closed-deals',
  SYSTEM_ARCHIVED_DEALS = 'archived-deals',
  SYSTEM_USERS = 'users',
  SYSTEM_MANDATES = 'mandates',
  SYSTEM_NEWS = 'news',
  SYSTEM_NEWS_EDIT = 'edit',
  SYSTEM_NEWS_CREATE = 'create',

  // ROOT->DEALS sub pages
  DEALS_CREATE = 'create',
  DEALS_EDIT = 'edit',
  DEALS_DETAILS = 'details',
  DEALS_LENDERS = 'lenders',
  DEALS_TEAM = 'team',
  DEALS_SPONSOR_TEAM = 'sponsor-team',
  DEALS_QA = 'questions-and-answers',
  DEALS_INDICATIVE_TERMS = 'indicative-terms',
  DEALS_DATA_ROOM = 'data-room',
  CREATE_PORTFOLIO = 'create-portfolio',

  // ROOT->MANAGEMENT sub pages
  MANAGEMENT_USERS = 'users',
  MANAGEMENT_CRITERIA = 'criteria',
  MANAGEMENT_COMPANY = 'company',

  // ROOT->PROFILE SEARCH sub pages
  PROFILE_SEARCH_CREATE = 'create',

  // ROOT->ACCOUNT SETTINGS sub pages
  ACCOUNT_SETTINGS = 'account',
  NOTIFICATION_SETTINGS = 'notifications',
}
