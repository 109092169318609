<button
  type="button"
  class="button-no-styles close-button__container"
  [ngClass]="{
    'close-button__container--small': size === CloseButtonSizeEnum.SM
  }"
  (click)="onButtonClick()"
>
  <app-icon class="close-button__icon" [size]="iconSize" svgPath="close.svg"></app-icon>
</button>
