import { IDealInvitedLenderModel } from '@app/features/deal-lenders';

export const sortAlphabetically = (nameA: string, nameB: string) => {
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const sortInvitesByTerAlphabetically = (inviteA: any, inviteB: any, attrName: string) => {
  // First, sort by tier in ascending order
  if (inviteA.tier !== inviteB.tier) {
    if (inviteA.tier === 0) return 1; // Place 0 tier at the end
    if (inviteB.tier === 0) return -1; // Place 0 tier at the end
    return inviteA.tier - inviteB.tier;
  }
  // If tiers are the same, sort alphabetically by name
  return inviteA[attrName].localeCompare(inviteB[attrName]);
};

export const sortExternalInvitesByNdaAlphabetically = (
  inviteA: IDealInvitedLenderModel,
  inviteB: IDealInvitedLenderModel
) => {
  // Place the NDAs first and Deal invites last
  if (inviteA.isNda && !inviteB.isNda) return -1;
  if (!inviteA.isNda && inviteB.isNda) return 1;

  // and now sort alphabetically
  return inviteA.email.localeCompare(inviteB.email);
};
