import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ButtonColorsEnum } from '@shared/enums';

import { BUTTONS_AS_ICONS_BREAKPOINT } from '@shared/constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fr-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit, OnDestroy {
  @Input()
  public type = 'button'; // button || reset || submit

  @Input()
  public color: ButtonColorsEnum = ButtonColorsEnum.PRIMARY_LIGHT;

  @Input()
  public icon: string;

  @Input()
  public iconSize = '24';

  @Input()
  public isIconOnly: boolean;

  @Input()
  public withoutIcon: boolean;

  @Input()
  public isIconWithBorderOnly: boolean;

  @Input()
  public noShadow: boolean;

  @Input()
  public disabled = false;

  @Input()
  public alignContentRight = false;

  @Input()
  public smallScreenShrink = false;

  @Input()
  public tooltipText: string;

  @Input()
  public size: 'regular' | 'small' = 'regular';

  @Output() public clickButton: EventEmitter<MouseEvent> = new EventEmitter();

  public ButtonColorsEnum = ButtonColorsEnum;

  private subscription: Subscription;

  constructor(private breakpointObserver: BreakpointObserver, private cd: ChangeDetectorRef) {}

  public ngOnInit() {
    if (this.smallScreenShrink) {
      this.subscription = this.breakpointObserver.observe([BUTTONS_AS_ICONS_BREAKPOINT]).subscribe((state) => {
        this.isIconWithBorderOnly = state.matches;
        this.cd.markForCheck();
      });
    }
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public onClick(event: MouseEvent): void {
    this.clickButton.emit(event);
  }
}
