import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ErrorHandlerService } from '@features/error-handler/services';
import { ErrorHandlerActions } from '@features/error-handler/state/actions';
import { mapErrorMessage } from '@features/error-handler/utils';
import { mapPayload } from '@shared/utils';

@Injectable()
export class ErrorHandlerEffects {
  public errorOccurred$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorHandlerActions.errorOccurred),
        mapPayload(),
        tap((error) => {
          this.errorHandlerService.newError(mapErrorMessage(error));
        })
      ),
    { dispatch: false }
  );

  public navigation$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        tap(() => {
          this.errorHandlerService.hide();
        })
      ),
    { dispatch: false }
  );

  public clearErrors$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorHandlerActions.clearErrors),
        tap(() => {
          this.errorHandlerService.hide();
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private errorHandlerService: ErrorHandlerService) {}
}
