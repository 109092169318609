export const PERCENT_PIPE_FORMAT = '1.0-2';
export const DATE_PIPE_FORMAT = 'do MMM y';
export const CURRENCY_PIPE_FORMAT = '1.0';
export const NUMBER_PIPE_FORMAT = '1.0-0';

export const PIPE_FORMATS = {
  PERCENT: PERCENT_PIPE_FORMAT,
  DATE: DATE_PIPE_FORMAT,
  CURRENCY: CURRENCY_PIPE_FORMAT,
  NUMBER: NUMBER_PIPE_FORMAT,
};
