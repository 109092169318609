const config = {
  eol: '\r\n',
  separator: ',',
};

export const generateCSV = (header, data) => {
  const csv = `${getHeader(header)}${getBody(data)}`;

  const file = new Blob([csv], {
    type: 'text/csv;charset=UTF-8',
  });

  return URL.createObjectURL(file);
};

const getHeader = (header) => {
  let row = '';

  header.forEach((key) => (row += `${key}${config.separator}`));
  row = row.slice(0, -1);

  return `${row}${config.eol}`;
};

export const getBody = (data) => {
  let body = '';
  for (let i = 0; i < data.length; i++) {
    let bodyRow = '';
    for (const index in data[i]) {
      if ({}.hasOwnProperty.call(data[i], index)) {
        bodyRow += `"${data[i][index]}"${config.separator}`;
      }
    }
    bodyRow = bodyRow.slice(0, -1);
    body += bodyRow + '\r\n';
  }

  return `${body}${config.eol}`;
};
