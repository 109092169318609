import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainContentComponent {
  @Input()
  public isNavigationCollapsed: boolean;

  @Output()
  public scrolledToPoint: EventEmitter<void> = new EventEmitter<void>();

  public onScrolledToPoint(): void {
    this.scrolledToPoint.emit();
  }
}
