import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'menu-icon',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuIconComponent {
  @Input() public isExpanded = false;
  @Input() public size: 'small' | 'medium' | 'large' = 'medium';

  @HostBinding('class') public get class() {
    return `fr-animatedIcon fr-animatedIcon--${this.size}`;
  }
}
