import { select } from '@ngrx/store';
import { filter, mapTo, switchMap, take } from 'rxjs/operators';

import { getIsUserStateInitialized } from '@features/auth/state/selectors';
import { selectRouterUrl } from '@shared/state/selectors';

export const waitUntilUserInitialized = (store$) =>
  switchMap((value) => store$.pipe(select(getIsUserStateInitialized), filter(Boolean), take(1), mapTo(value)));

export const waitUntilRouterInitialized = (store$) =>
  switchMap((value) => store$.pipe(select(selectRouterUrl), filter(Boolean), take(1), mapTo(value)));
