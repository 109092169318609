<div class="fr-errorPage-main">
  <img src="assets/images/logo.png" alt="Fundre" class="fr-errorPage-logo">
  <h1 class="fr-errorPage-title"> 
    <ng-content select="[title]"></ng-content>
  </h1>
  <div class="fr-errorPage-description">
    <p>
      <ng-content select="[description]"></ng-content>
    </p>
    <p class="fr-errorPage-url"><ng-content select="[url]"></ng-content></p>
  </div>
</div>  
