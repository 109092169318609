import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorPages } from '../enums/error-pages.enum';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly allowedErrorPages = [ErrorPages.E500];

  constructor(private router: Router) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (this.allowedErrorPages.includes(err.status)) {
          this.router.navigate([`/${err.status}`], { state: { errorUrl: this.router.url } });
        }
        return next.handle(request);
      })
    );
  }
}
