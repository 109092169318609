import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input()
  public src: string;

  @Input()
  public alt: string;

  @Input()
  public isWhite = false;
}
