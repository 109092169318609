import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

const enterStyles = style({
  opacity: 1,
});

const leaveStyles = style({
  opacity: 0,
});

const collapseExpandTimings = '200ms ease';

export const tooltipAnimation: AnimationTriggerMetadata = trigger('tooltip', [
  transition(':enter', [style({ opacity: 0 }), animate(collapseExpandTimings, enterStyles)]),
  transition(':leave', [animate(collapseExpandTimings, leaveStyles)]),
]);

export const animations = [tooltipAnimation];
