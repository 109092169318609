import { ComponentRef, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { TooltipComponent } from './tooltip.component';
import { TOOLTIP_DEFAULT_OFFSET, TooltipOffset, TooltipPosition, TOOLTIP_POSITIONS_SETUP } from './tooltip.util';

@Directive({ selector: '[fr-tooltip]' })
export class TooltipDirective implements OnInit, OnChanges, OnDestroy {
  @Input('fr-tooltip')
  public text = '';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('fr-tooltip-color')
  public color: string;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('fr-tooltip-position')
  public position: TooltipPosition = 'right';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('fr-tooltip-offsetY')
  public offsetY = 0;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('fr-tooltip-offsetX')
  public offsetX = 0;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('fr-tooltip-hidden')
  public hidden: boolean;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('fr-tooltip-is-menu')
  public isMenu: boolean;

  private overlayRef: OverlayRef;
  private tooltipRef: ComponentRef<TooltipComponent>;
  constructor(private overlay: Overlay, private hostElement: ElementRef) {}

  public ngOnInit(): void {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.hostElement)
      .withPositions(this.getOverlayPosition());

    const scrollStrategy = this.overlay.scrollStrategies.close();

    this.overlayRef = this.overlay.create({ positionStrategy, scrollStrategy, disposeOnNavigation: true });
  }

  public ngOnChanges() {
    if (this.tooltipRef) {
      this.hideTooltip();
    }
  }

  public ngOnDestroy(): void {
    this.dispose();
  }

  @HostListener('mouseleave')
  public onHostMouseLeave(): void {
    this.hideTooltip();
  }

  @HostListener('mouseenter')
  public onHostMouseEnter(): void {
    this.showTooltip();
  }

  private dispose(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  private hideTooltip(): void {
    if (!this.overlayRef.hasAttached()) return;

    this.overlayRef.detach();
  }

  private showTooltip(): void {
    if (this.hidden || !this.text || this.overlayRef.hasAttached()) return;

    this.tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
    this.tooltipRef.instance.text = this.text;
    this.tooltipRef.instance.color = this.color;
    if (this.isMenu) {
      this.tooltipRef.instance.menuClass = 'menu-tooltip';
    }
  }

  private getOverlayPosition(): ConnectedPosition[] {
    const offset = this.getFinalOffset();

    return TOOLTIP_POSITIONS_SETUP[this.position](offset) as ConnectedPosition[];
  }

  private getFinalOffset(): TooltipOffset {
    return {
      offsetX: this.offsetX ? this.offsetX : TOOLTIP_DEFAULT_OFFSET[this.position].offsetX,
      offsetY: this.offsetY ? this.offsetY : TOOLTIP_DEFAULT_OFFSET[this.position].offsetY,
    };
  }
}
