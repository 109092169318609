import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { InlineSvgDirective } from '../inline-svg/inline-svg.directive';

@NgModule({
  declarations: [InlineSvgDirective],
  exports: [InlineSvgDirective],
  imports: [HttpClientModule],
})
export class InlineSvgModule {}
