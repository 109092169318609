<aside
  class="main-content__side-menu"
  [class.main-content__side-menu--collapsed]="isNavigationCollapsed"
>
  <navigation-container></navigation-container>
</aside>

<main
  [scrollDirective]="scrollContainer"
  (scrolledToPoint)='onScrolledToPoint()'
  class="main-content__content-container"
  [class.main-content__content-container--wide]="isNavigationCollapsed"
  
>
  <div #scrollContainer>
    <router-outlet></router-outlet>
  </div>
</main>
