import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { waitUntilUserInitialized } from '@features/auth/utils';
import { IAppState } from '@shared/models';
import { NewsOverlayComponent, NotificationOverlayService } from '@app/features/overlay';
import { NewsActions } from '@features/news/state/actions/news.actions';
import { NewsService } from '../../services/news.service';
import { mapPayload } from '@app/shared/utils';
import {
  ProfileActions,
  UserActions,
  UserRolesEnum,
  getCurrentAccountType,
  getCurrentUserRole,
} from '@app/features/auth';

@Injectable()
export class NewsEffects {
  public userNews$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      // On login, mark as read and whe switching account we need to retrieve news to update state
      ofType(NewsActions.getUserNewsInitiated, NewsActions.markAsReadNewsSuccess, ProfileActions.accountTypeChanged),
      waitUntilUserInitialized(this.store$),
      withLatestFrom(this.store$.pipe(select(getCurrentAccountType)), this.store$.pipe(select(getCurrentUserRole))),
      filter(([, , userRole]) => userRole !== UserRolesEnum.SUPER_ADMIN), // Super admins does not get news notifications
      switchMap(([, accountType]) =>
        this.newsService.getUserNews(accountType).pipe(
          map((data) => NewsActions.getUserNewsSuccess({ payload: data })),
          catchError(() => of(NewsActions.getUserNewsFailed()))
        )
      )
    )
  );

  public displayNews$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewsActions.getUserNewsSuccess),
        waitUntilUserInitialized(this.store$),
        // Do nothing if there are no news
        filter((data: { payload: any }) => data.payload.length),
        switchMap(() => this.notificationOverlay.displayNotification(NewsOverlayComponent))
      ),
    { dispatch: false }
  );

  public closeNews$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logoutInitiated),
        tap(() => this.notificationOverlay.closeNotifications(null))
      ),
    { dispatch: false }
  );

  public markAsReadNews$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.markAsReadNewsInitiated),
      mapPayload(),
      exhaustMap((newsList) =>
        this.newsService.markAsReadNews(newsList.map((news) => news.id)).pipe(
          map((responseData) => NewsActions.markAsReadNewsSuccess({ payload: responseData })),
          catchError((responseData) => of(NewsActions.markAsReadNewsFailed({ payload: responseData })))
        )
      )
    )
  );

  public markAsSeenNews$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.markAsSeenNewsInitiated),
      mapPayload(),
      exhaustMap((newsList) =>
        this.newsService.markAsSeenNews(newsList.map((news) => news.id)).pipe(
          map(() => NewsActions.markAsSeenNewsSuccess()),
          catchError(() => of(NewsActions.markAsSeenNewsFailed()))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<IAppState>,
    private newsService: NewsService,
    private notificationOverlay: NotificationOverlayService
  ) {}
}
