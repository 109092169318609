import { Action, createReducer, on } from '@ngrx/store';

import { INavigationOpenStateModel } from '@features/navigation/models';
import { NavigationPageActions } from '@features/navigation/state/actions';

export const initialNavigationOpenState: INavigationOpenStateModel = {
  isNavigationCollapsed: false,
};

const reducer = createReducer(
  initialNavigationOpenState,
  on(NavigationPageActions.navigationToggled, (state) => ({
    ...state,
    isNavigationCollapsed: !state.isNavigationCollapsed,
  })),
  on(NavigationPageActions.navigationExpanded, (state) => ({
    ...state,
    isNavigationCollapsed: false,
  })),
  on(NavigationPageActions.navigationCollapsed, (state) => ({
    ...state,
    isNavigationCollapsed: true,
  }))
);

export function navigationOpenReducer(
  state: INavigationOpenStateModel | undefined,
  action: Action
): INavigationOpenStateModel {
  return reducer(state, action);
}
