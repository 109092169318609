import { DealStrategyEnum } from '@features/deals/enums';

export const STRATEGIES = [
  {
    key: DealStrategyEnum.CORE,
    value: 'Core',
  },
  {
    key: DealStrategyEnum.CORE_PLUS,
    value: 'Core+',
  },
  {
    key: DealStrategyEnum.VALUE_ADD,
    value: 'Value-add',
  },
  {
    key: DealStrategyEnum.OPPORTUNISTIC,
    value: 'Opportunistic',
  },
  {
    key: DealStrategyEnum.DEVELOPMENT,
    value: 'Development',
  },
];

export const STRATEGIES_WITH_MIXED = [
  {
    key: DealStrategyEnum.MIXED,
    value: 'Multi-Strategy Portfolio',
  },
  ...STRATEGIES,
];

export const DEAL_STRATEGIES_MAP = STRATEGIES_WITH_MIXED.reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});
