import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, withLatestFrom } from 'rxjs/operators';

import { InitActions } from '@shared/state/actions';
import { IConvertCurrencyActionModel, ICurrencyRateMapModel } from '@features/currency-rates/models';
import { getCurrencyRatesMap } from '@features/currency-rates/state/selector';
import { CurrencyRatesActions } from '@features/currency-rates/state/actions';
import { CurrencyRatesService } from '@features/currency-rates/services';
import { mapErrorAction } from '@features/error-handler';
import { mapPayload } from '@shared/utils';
import { IAppState } from '@shared/models';
import { CurrencyEnum } from '@shared/enums';
import { getCurrentTenantCurrency, getIsUserAdmin, waitUntilUserInitialized } from '@features/auth';

@Injectable()
export class CurrencyRatesEffects {
  public getCurrencyRates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InitActions.pageLoggedInInitialized, CurrencyRatesActions.getCurrencyRatesInitiated),
      waitUntilUserInitialized(this.store$),
      withLatestFrom(this.store$.pipe(select(getIsUserAdmin))),
      filter(([, isSuperAdmin]) => !isSuperAdmin),
      exhaustMap(() =>
        this.currencyRatesService.get().pipe(
          map((result) => CurrencyRatesActions.getCurrencyRatesSuccess({ payload: result })),
          catchError((error) => of(CurrencyRatesActions.getCurrencyRatesFailed({ payload: error })))
        )
      )
    )
  );

  public convertCurrencyAction$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyRatesActions.convertCurrencyActionInitiated),
      mapPayload(),
      withLatestFrom(this.store$.pipe(select(getCurrentTenantCurrency)), this.store$.pipe(select(getCurrencyRatesMap))),
      map(
        ([convertCurrencyPayload, currentTenantCurrency, currencyRatesMap]: [
          IConvertCurrencyActionModel,
          CurrencyEnum,
          ICurrencyRateMapModel
        ]) => {
          const { action, payload } = convertCurrencyPayload;
          return action({ payload: { actionPayload: payload, currentTenantCurrency, currencyRatesMap } });
        }
      )
    )
  );

  public genericError$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(CurrencyRatesActions.getCurrencyRatesFailed), mapErrorAction())
  );

  constructor(
    private store$: Store<IAppState>,
    private actions$: Actions,
    private currencyRatesService: CurrencyRatesService
  ) {}
}
