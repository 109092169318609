import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { SpinnerCoverService } from '@features/spinner/services';

@Component({
  selector: 'spinner-cover-container',
  templateUrl: './spinner-cover-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerCoverContainerComponent {
  public isLoadingObservable$: Observable<boolean> = this.spinnerCoverService.isLoadingDebounce$;
  public isLoading = false;

  constructor(private spinnerCoverService: SpinnerCoverService, private cdRef: ChangeDetectorRef) {
    this.isLoadingObservable$.subscribe((loading) => {
      // Detect changes is needed here to hide spinner when loading ends in certain scenarios where
      // There are certain scenarios where view is not updated and spinner iconrrectly remains in the view
      // if no change detection is fired.
      this.isLoading = loading;
      this.cdRef.detectChanges();
    });
  }
}
