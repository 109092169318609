import { createAction, props } from '@ngrx/store';
import { IApiErrorModel, ITenantUpdateModel } from '@shared/models';
import { IUserPreferencesUpdateModel, IUserUpdateModel } from '@features/auth/models';
import { AccountTypeEnum } from '@shared/enums';

export const accountTypeInitiated = createAction(
  '[Profile] Account type initiated',
  props<{ payload: AccountTypeEnum }>()
);

export const accountTypeChanged = createAction('[Profile] Account type changed', props<{ payload: AccountTypeEnum }>());

export const profileChangeInitiated = createAction(
  '[Profile Page] User info updated',
  props<{ payload: IUserUpdateModel }>()
);

export const profileChangeSuccess = createAction(
  '[User Api] User info change success',
  props<{ payload: IUserUpdateModel }>()
);

export const profileChangeFailed = createAction(
  '[User Api] User info change failed',
  props<{ payload: IApiErrorModel }>()
);

export const companyChangeInitiated = createAction(
  '[Management Page] Change company initiated',
  props<{ payload: ITenantUpdateModel }>()
);

export const companyChangeSuccess = createAction(
  '[Tenants Api] Change company success',
  props<{ payload: ITenantUpdateModel }>()
);

export const companyChangeFailed = createAction(
  '[Tenants Api] Change company failed',
  props<{ payload: IApiErrorModel }>()
);

export const userPreferencesChangeInitiated = createAction(
  '[User preferences Page] User preferences update initiated',
  props<{ payload: IUserPreferencesUpdateModel }>()
);

export const userPreferencesChangeSuccess = createAction('[User preferences Page] User preferences update success');

export const userPreferencesChangeFailed = createAction('[User preferences Page] User preferences update failed');

export const ProfileActions = {
  accountTypeInitiated,
  accountTypeChanged,
  profileChangeInitiated,
  profileChangeSuccess,
  profileChangeFailed,
  companyChangeInitiated,
  companyChangeSuccess,
  companyChangeFailed,

  userPreferencesChangeInitiated,
  userPreferencesChangeSuccess,
  userPreferencesChangeFailed,
};
