import { createAction, props } from '@ngrx/store';

export const navigationToggled = createAction('[Navigation] Navigation toggles');

export const navigationExpanded = createAction('[Navigation] Navigation expands');

export const navigationCollapsed = createAction('[Navigation] Navigation collapses');

export const switchAccountTypeInitiated = createAction('[Navigation] Switch account type initiated');

export const userGuideClick = createAction('[Navigation] User guid click');

export const currentItemChanged = createAction('[Navigation] Current item changed', props<{ payload: string }>());

export const dealClosedStateLoaded = createAction(
  '[Navigation] Deal closed state loaded',
  props<{ payload: { isClosed: boolean } }>()
);

export const NavigationPageActions = {
  switchAccountTypeInitiated,
  navigationToggled,
  navigationExpanded,
  navigationCollapsed,
  userGuideClick,
  currentItemChanged,
  dealClosedStateLoaded,
};
