<div class="navigation__container"  [class.navigation__container--hidden]="!menuLinksList?.length">
  <div class="navigation__top-container">
    <div class="navigation__logo-wrapper">
      <img
        [src]="isNavigationCollapsed ? 'assets/images/logo-short.png' : 'assets/images/logo.png'"
        alt="Logo"
        class="navigation__logo"
      />
    </div>

    <avatar
      class="navigation__profile-photo"
      [src]="profileInfo?.picture"
      alt="profile photo"
    ></avatar>

    <div class="navigation__profile-info-wrapper" [class.navigation__profile-info-wrapper--collapsed]="isNavigationCollapsed">
      <span class="navigation__profile-name has-text-weight-bold">{{profileInfo.fullName}}</span>
      <span class="navigation__profile-company has-text-weight-medium">{{profileInfo.company}}</span>
    </div>
    <div class="navigation__profile-status-container">
      <span class="navigation__profile-status has-text-weight-bold">
        {{ ACCOUNT_TYPES[accountType] }}
      </span>
      <button
        *ngIf="accountType !== SidebarAdditionalAccountTypesEnum.ADMIN"
        class="button-no-styles navigation__profile-status-button"
        (click)="onSwitchAccount($event)"
      >
        Switch account
      </button>
    </div>

    <div class="navigation__list">
      <navigation-item
        *ngFor="let link of menuLinksList; trackBy: trackByMenuFn"
        [link]="link"
        [isNavigationCollapsed]="isNavigationCollapsed"
        [isLinkActive]="currentNavigationItem.includes(link.route)"
        (navigationSelected)="onNavigationSelected($event)"
      ></navigation-item>
    </div>
  </div>

  <navigation-item
    class="navigation__middle-container"
    [isLinkActive]="currentNavigationItem === 'profile-settings'"
    [class.navigation__middle-container--admin]="accountType === SidebarAdditionalAccountTypesEnum.ADMIN"
    [link]="profileLink"
    [isNavigationCollapsed]="isNavigationCollapsed"
    (navigationSelected)="onNavigationSelected($event)"
  ></navigation-item>

  <div class="navigation__footer-container">
    <button
      *ngIf="accountType !== SidebarAdditionalAccountTypesEnum.ADMIN"
      class="button-no-styles navigation__toggle-button navigation__toggle-button--user-guide"
      [class.navigation__toggle-button--collapsed]="isNavigationCollapsed"
      [fr-tooltip]="'User guide'"
      [fr-tooltip-hidden]="!isNavigationCollapsed"
      [fr-tooltip-is-menu]='true'
      (click)="onUserGuideClick()"
    >
      <app-icon class="navigation__toggle-button-icon" svgPath="user-guide.svg" size="20"></app-icon>
      <span
        class="navigation__toggle-button-text"
        [class.navigation__toggle-button-text--collapsed]="isNavigationCollapsed"
      >
        User guide
      </span>
    </button>

    <button
      class="button-no-styles navigation__toggle-button"
      [class.navigation__toggle-button--collapsed]="isNavigationCollapsed"
      (click)="navigationToggled.emit()"
    >
      <span class="navigation__toggle-button-icon navigation__toggle-button-icon-navToggle">
        <menu-icon  size="medium" [isExpanded]="!isNavigationCollapsed"></menu-icon>
      </span>
      <span
        class="navigation__toggle-button-text"
        [class.navigation__toggle-button-text--collapsed]="isNavigationCollapsed"
      >
        Collapse menu
      </span>
    </button>
    <div
      class="navigation__sign-out-container"
      [class.navigation__sign-out-container--collapsed]="isNavigationCollapsed"
    >
      <button
        class="button-no-styles has-text-weight-semibold navigation__sign-out"
        (click)="onLogout()"
      >
        Sign out
      </button>
    </div>
    <div
      class="navigation__copyright-container"
      [class.navigation__copyright-container--collapsed]="isNavigationCollapsed"
    >
      <div class="navigation__copyright">{{copyright}}</div>
    </div>
  </div>
</div>
