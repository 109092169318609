import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator, select } from '@ngrx/store';
import { filter, map, switchMap, take } from 'rxjs/operators';

export const filterIfEmptyFromSelector = (store$, selector, actionType?) =>
  switchMap(({ type }) =>
    store$.pipe(
      select(selector),
      take(1),
      filter((list: any[]) => {
        if (actionType) {
          return type.includes(actionType) ? list && !list.length : true;
        }

        return list && !list.length;
      })
    )
  );

/**
 * After specific actions we may want to refresh some Entity T
 * This util created effect that listens to specific actions in system and triggers load of entity T when user comes back to T listing
 *
 * @param actions$ ngrx actions object
 * @param pageInitActions actions that should start load of T entity
 * @param refreshConditionActions actions that means that we need to refresh T entity
 * @param loadInitAction action that start load of T entity
 * @returns effect
 */
export const createRefreshEffect = (
  actions$: Actions,
  pageInitActions: ActionCreator<any, any>[],
  refreshConditionActions: ActionCreator<any, any>[],
  loadInitAction: ActionCreator<any, any>
) =>
  createEffect(() =>
    actions$.pipe(
      ofType(...pageInitActions),
      switchMap(() => actions$.pipe(ofType(...refreshConditionActions), take(1))),
      switchMap(() => actions$.pipe(ofType(...pageInitActions), take(1))),
      map(() => loadInitAction())
    )
  );
