export enum HotelCategoryEnum {
  BUDGET = 'Budget',
  MIDSCALE = 'Midscale',
  UPSCALE = 'Upscale',
  UPPER_UPSCALE = 'UpperUpscale',
  LUXURY = 'Luxury',
  HOSTELS = 'Hostels',
  SERVICE_APARTMENTS = 'ServicedApartments',
  RESORT = 'Resort',
}
