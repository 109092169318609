export enum CurrencyEnum {
  USD = 'Usd',
  EUR = 'Eur',
  GBP = 'Gbp',
  CHF = 'Chf',
  CZK = 'Czk',
  DKK = 'Dkk',
  NOK = 'Nok',
  PLN = 'Pln',
  SEK = 'Sek',
}
