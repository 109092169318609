import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuIconComponent } from './menu/menu.component';

@NgModule({
  declarations: [MenuIconComponent],
  exports: [MenuIconComponent],
  imports: [CommonModule],
})
export class AnimatedIconsModule {}
