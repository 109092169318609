import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

import { AccountTypeEnum } from '@shared/enums';

@Component({
  selector: 'switch-account-type',
  templateUrl: './switch-account-type.component.html',
  styleUrls: ['./switch-account-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchAccountTypeComponent {
  @Output()
  public confirm: EventEmitter<AccountTypeEnum | null> = new EventEmitter();

  @Input()
  public activeAccountType: AccountTypeEnum;

  @Input()
  public tabs: any[];

  public onConfirm(activeAccountType: AccountTypeEnum | null) {
    this.confirm.emit(activeAccountType);
  }
}
