export enum TenantPropertyEnum {
  ID = 'id',
  NAME = 'name',
  SUB_TYPE = 'subType',
  ABOUT = 'about',
  ABOUT_MAX_CHAR = 'aboutMaxChar',
  PHONE = 'phone',
  LOGO_URL = 'logoUrl',
  LOGO_COORDS = 'imageCoordinates',
  BANNER_URL = 'bannerUrl',
  BANNER_COORDS = 'bannerCoordinates',
  WEBSITE_URL = 'websiteUrl',
  ADDRESS = 'address',
  STATUS = 'status',
  METADATA = 'metadata',
  PRIMARY_ACCOUNT_TYPE = 'primaryAccountType',
  DEFAULT_CURRENCY = 'defaultCurrency',
  COMMENT = 'comment',
  COUNT_CLOSED_DEALS = 'countClosedDeals',
  COUNT_LIVE_DEAL = 'countLiveDeal',
  COUNT_MANDATE = 'countMandate',
  VALIDATION_ERRORS = 'validationErrors',
  VALIDATION_ERROR = 'validationError',
}
