import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AccountTypeEnum } from '@shared/enums';
import { ModalOverlayService } from '@features/overlay';
import { getCurrentAccountType } from '@features/auth';
import { IAppState } from '@shared/models';
import { getSwitchAccountTabs } from '@features/navigation/state/selectors';

@Component({
  selector: 'switch-account-type-container',
  templateUrl: './switch-account-type-container.component.html',
  styleUrls: ['./switch-account-type-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchAccountTypeContainerComponent implements OnInit {
  public activeAccountType$: Observable<AccountTypeEnum>;
  public tabs$: Observable<any>;

  constructor(private overlayService: ModalOverlayService, private store$: Store<IAppState>) {}

  public ngOnInit(): void {
    this.activeAccountType$ = this.store$.pipe(select(getCurrentAccountType));
    this.tabs$ = this.store$.pipe(select(getSwitchAccountTabs));
  }

  public onConfirm(value: AccountTypeEnum | null): void {
    this.overlayService.closeModal(value);
  }
}
