import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IAppState } from '@shared/models';
import { getIsNavigationCollapsed } from '@features/navigation';
import { InfinityScrollActions } from '@shared/state/actions';

@Component({
  selector: 'main-content-container',
  templateUrl: './main-content-container.component.html',
  styleUrls: ['./main-content-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainContentContainerComponent implements OnInit {
  public isNavigationCollapsed$: Observable<boolean>;

  constructor(private store$: Store<IAppState>) {}

  public ngOnInit() {
    this.isNavigationCollapsed$ = this.store$.pipe(select(getIsNavigationCollapsed));
  }

  public onScrolledToPoint() {
    this.store$.dispatch(InfinityScrollActions.onScrolledToPoint());
  }
}
