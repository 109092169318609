export const DEFAULT_LOCALE = 'en-US';
export const STANDARD_DATE = 'yyyy-MM-dd';
export const STANDARD_DISPLAY_DATE = 'd MMMM y';
export const DEFAULT_NUMBER_REPRESENTATION = '1.0-2';
export const FRACTION_NUMBER_REPRESENTATION = '1.2-2';
export const ROUNDED_NUMBER_REPRESENTATION = '1.0-0';

export const DEFAULT_COORDINATE_REPRESENTATION = '1.0-6';
export const FRACTION_COORDINATE_REPRESENTATION = '1.6-6';
export const ROUNDED_COORDINATE_REPRESENTATION = '1.0-0';
