import { AnswersEnum } from '@shared/enums';

export const ANSWERS_CONSTANTS = [
  {
    value: AnswersEnum.YES,
    key: true,
  },
  {
    value: AnswersEnum.NO,
    key: false,
  },
];
