import { createSelector } from '@ngrx/store';

import { selectUrl } from '@shared/state/selectors';
import { ADMIN_MENU_LINKS, NAVIGATION_MENU_LINKS, PROFILE_LINK } from '@features/navigation/constants';
import { mapLinkWithStateFactory } from '@features/navigation/utils';
import { getIsUserAdmin } from '@features/auth';
import { getNavigationOpenState, getNavigationCurrentItemState } from './navigation-base.selectors';

export const getIsNavigationCollapsed = createSelector(getNavigationOpenState, (state) => state.isNavigationCollapsed);

export const getCurrentNavItem = createSelector(getNavigationCurrentItemState, (state) => state.currentNavItem);

export const getSideMenuLinks = createSelector(selectUrl, getIsUserAdmin, (url, isAdmin) =>
  // Avoid returning wrong links when a null is retrieved by getIsUserAdmin when user is still not ready.
  isAdmin !== null ? (isAdmin ? ADMIN_MENU_LINKS : NAVIGATION_MENU_LINKS).map(mapLinkWithStateFactory(url)) : []
);

export const getProfileLink = createSelector(selectUrl, (url) => mapLinkWithStateFactory(url)(PROFILE_LINK));
