import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { ModalOverlayService, OVERLAY_CONFIG } from '@features/overlay';
import { NavigationPageActions } from '@features/navigation/state/actions';
import { SwitchAccountTypeContainerComponent } from '@features/navigation/components';
import { ProfileActions } from '@features/auth';
import { AUTO_COLLAPSE_SIDE_MENU_BREAKPOINT } from '@shared/constants';
import { AccountTypeEnum, AppRoutesEnum } from '@shared/enums';
import { download } from '@app/shared/utils';
import { Location } from '@angular/common';
import { IAppState } from '@app/shared/models';
import { NavigationStart, Router } from '@angular/router';

@Injectable()
export class NavigationEffects {
  public openProfileOverlay$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationPageActions.switchAccountTypeInitiated),
      switchMap(() => this.overlayService.openModal(SwitchAccountTypeContainerComponent, OVERLAY_CONFIG)),
      filter(Boolean),
      map((result: AccountTypeEnum) => ProfileActions.accountTypeChanged({ payload: result }))
    )
  );

  public autoCollapseSideMenu$: Observable<Action> = createEffect(() =>
    this.breakpointObserver
      .observe([AUTO_COLLAPSE_SIDE_MENU_BREAKPOINT])
      .pipe(
        map((state: BreakpointState) =>
          state.matches ? NavigationPageActions.navigationCollapsed() : NavigationPageActions.navigationExpanded()
        )
      )
  );

  public userGuideClick$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationPageActions.userGuideClick),
        tap(() => download('assets/documents/UserGuide-FundRE.pdf', 'UserGuide-FundRE.pdf'))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private overlayService: ModalOverlayService,
    private breakpointObserver: BreakpointObserver,
    private store$: Store<IAppState>,
    private router: Router,
    private readonly location: Location
  ) {
    this.selectItemNavigation();
    // Handle nav item selection when user goes back and forward with browser navigation buttons
    this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.selectItemNavigation();
      }
    });
  }

  private selectItemNavigation(): void {
    const fullPath = this.location.path();
    const isDealDetail = fullPath.includes(`${AppRoutesEnum.DEALS}/${AppRoutesEnum.DEALS_DETAILS}`);

    if (!isDealDetail) {
      this.store$.dispatch(NavigationPageActions.currentItemChanged({ payload: fullPath }));
    }
  }
}
