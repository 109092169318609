import { LoanTypeEnum } from '@shared/enums';

export const LOAN_TYPES = [
  {
    value: 'Senior Loan',
    key: LoanTypeEnum.SENIOR_LOAN,
  },
  {
    value: 'Mezzanine',
    key: LoanTypeEnum.MEZZANINE,
  },
  {
    value: 'Whole Loan',
    key: LoanTypeEnum.WHOLE_LOAN,
  },
  {
    value: 'Preferred Equity',
    key: LoanTypeEnum.PREFERRED_EQUITY,
  },
  {
    value: 'Bridge Loan',
    key: LoanTypeEnum.BRIDGE_LOAN,
  },
];

export const LOAN_TYPES_WITH_COMBINED = [
  ...LOAN_TYPES,
  {
    value: 'Senior + Mezzanine',
    key: LoanTypeEnum.SENIOR_PLUS_MEZZANINE,
  },
];

export const LOAN_TYPES_ORDER = LOAN_TYPES_WITH_COMBINED.reduce((obj, item, index) => {
  obj[item.value] = index;
  return obj;
}, {});

export const LOAN_TYPES_MAP = LOAN_TYPES_WITH_COMBINED.reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});
