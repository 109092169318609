import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DOMService } from '@shared/services';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private domService: DOMService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.domService.isIE && request.method === 'GET') {
      const customRequest = request.clone({
        headers: request.headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache'),
      });
      return next.handle(customRequest);
    }

    return next.handle(request);
  }
}
