import { IMetadataModel, INameModel, IPhoneModel } from '@shared/models';
import { UserRolesEnum } from '@features/auth/enums';
import { IUserPreferencesModel } from '@app/shared/models/i-user-preferences.model';

export interface IUserModel {
  id: string;
  email: string;
  title: string;
  address: string;
  role: UserRolesEnum;
  phone: IPhoneModel;
  name: INameModel;
  picture: string;
  comment: string;
  metadata: IMetadataModel;
  preferences: IUserPreferencesModel;
}
