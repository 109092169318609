import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { InlineSvgModule } from '../inline-svg/index';
import { IconComponent } from './icon.component';

@NgModule({
  declarations: [IconComponent],
  exports: [IconComponent],
  imports: [HttpClientModule, InlineSvgModule],
})
export class IconModule {}
