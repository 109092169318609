import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthInterceptor } from './interceptors';
import { UserEffects, ProfileEffects, AccountTypeEffects } from './state/effects';
import { authFeatureReducers } from './state/reducers';
import { authFeatureName } from './state/selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(authFeatureName, authFeatureReducers),
    EffectsModule.forFeature([UserEffects, ProfileEffects, AccountTypeEffects]),
  ],
})
export class AuthModule {
  public static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
      ],
    };
  }
}
