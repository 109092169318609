import { createAction, props } from '@ngrx/store';

import { INewsModel } from '../../models/i-news.model';

export const getUserNewsInitiated = createAction('[User news] Get user news initiated');
export const getUserNewsSuccess = createAction('[User news] Get user news sucsess', props<{ payload: INewsModel[] }>());
export const getUserNewsFailed = createAction('[User news] Get user news failed');

export const markAsReadNewsInitiated = createAction(
  '[User news] Dismiss news initiated',
  props<{ payload: INewsModel[] }>()
);
export const markAsReadNewsSuccess = createAction(
  '[User news] Dismiss news successs',
  props<{ payload: INewsModel }>()
);
export const markAsReadNewsFailed = createAction('[User news] Dismiss news failed', props<{ payload: INewsModel }>());

export const markAsSeenNewsInitiated = createAction(
  '[User news] Mark as seen news initiated',
  props<{ payload: INewsModel[] }>()
);

export const markAsSeenNewsSuccess = createAction('[User news] Mark as seen news success');

export const markAsSeenNewsFailed = createAction('[User news] Mark as seen news failed');

export const NewsActions = {
  getUserNewsInitiated,
  getUserNewsSuccess,
  getUserNewsFailed,

  markAsReadNewsInitiated,
  markAsReadNewsSuccess,
  markAsReadNewsFailed,

  markAsSeenNewsInitiated,
  markAsSeenNewsSuccess,
  markAsSeenNewsFailed,
};
