<div
  *ngIf="text"
  class="notification__container"
  [ngClass]="'notification__container--' + type"
  [class.notification__container--spaced]="spacing"
>
  <app-icon
    size="16"
    verticalSize="16"
    class="notification__icon"
    [svgPath]="iconByType[type]"
    [ngClass]="'notification__icon--' + type"
  ></app-icon>
  <p [innerHTML]="text"></p>
  <button
    class="notification__btn-close button-no-styles"
    *ngIf="closable"
    (click)="hide.emit()"
  >
    <app-icon
      svgPath="times-solid.svg"
      size="12"
      verticalSize="12"
    ></app-icon>
  </button>
</div>
