import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from '@features/error-handler/services';

@Component({
  selector: 'error-handler-container',
  templateUrl: './error-handler-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorHandlerContainerComponent implements OnDestroy {
  @Input()
  public spacing = true;

  @Output()
  public errorToggle: EventEmitter<boolean> = new EventEmitter();

  public error$: Observable<string | null> = this.errorHandlerService.errorMessage$;

  constructor(private errorHandlerService: ErrorHandlerService) {
    this.error$.subscribe((message) => this.errorToggle.emit(message !== null));
  }

  public onHide() {
    event.stopPropagation();

    this.errorHandlerService.hide();
    this.errorToggle.emit(false);
  }

  public ngOnDestroy() {
    this.errorHandlerService.hide();
  }
}
