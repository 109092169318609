import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

const ASSETS_PATH = 'assets/icons';

/*
  That service handles the SVG icons requests and implements a cache. If svg was already requested (Having same svg in the page more than once) 
  this won't perform an extra request for each svg and if the data already exists in cache, subscribers will get the resource. 
  That's also useful when requesting icons later on execution time.
*/

@Injectable({
  providedIn: 'root',
})
export class InlineSvgService {
  private svgCache: { [key: string]: ReplaySubject<string> } = {};

  constructor(private http: HttpClient) {}

  /**
   * Retrieves the svg data ReplaySubject to subscribe to.
   * @param svgFileName
   * @returns
   */
  public getSvg(svgFileName: string): ReplaySubject<string> {
    if (!this.svgCache[svgFileName]) {
      this.svgCache[svgFileName] = new ReplaySubject<string>();
      this.requestSvg(svgFileName);
    }
    return this.svgCache[svgFileName];
  }

  /**
   * Http request for the SVG file
   * @param svgFileName
   */
  private requestSvg(svgFileName: string): void {
    this.http.get(`${ASSETS_PATH}/${svgFileName}`, { responseType: 'text' }).subscribe((data) => {
      this.svgCache[svgFileName].next(data);
    });
  }
}
