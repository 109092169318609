import { AccountTypeEnum } from '@shared/enums';

export const accountTypeToApiUrl = (accountType: AccountTypeEnum) => {
  // BROKER AND BORROWER have the same endpoints
  if (accountType === AccountTypeEnum.LENDER) {
    return accountType.toLowerCase();
  } else {
    return AccountTypeEnum.BORROWER.toLowerCase();
  }
};
// This does not unify sponsor and debt-advisors
export const newsAccountTypeToApiUrl = (accountType: AccountTypeEnum) => accountType.toLowerCase();
