import { formatNumber } from '@angular/common';
import RisNil from 'ramda/es/isNil';

import { AREA_UNITS_MAP, DEFAULT_LOCALE, PIPE_FORMATS } from '@shared/constants';
import { AreaUnitEnum, DurationUnitEnum } from '@shared/enums';

export const unitValueToString = (data: any): string => {
  let unit = data.unit.toLowerCase();

  if (+data.value < 2 && (data.unit === DurationUnitEnum.YEARS || data.unit === DurationUnitEnum.MONTHS)) {
    unit = unit.substring(0, unit.length - 1);
  }

  if (Object.values(AreaUnitEnum).includes(data.unit)) {
    unit = AREA_UNITS_MAP[data.unit] || unit;
  }

  return !RisNil(data.value) ? `${formatNumber(data.value, DEFAULT_LOCALE, PIPE_FORMATS.PERCENT)} ${unit}` : '';
};
