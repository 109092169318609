<div class="confirmation card" (clickedOutside)="onClickOutside()" clickOutside>
  <close-button (clickButton)="onCloseModal()"></close-button>

  <div class="confirmation__container">
    <div class="confirmation__title has-text-weight-semibold">
      Confirmation
    </div>

    <div class="confirmation__subtitle" [innerHTML]="title"></div>
  </div>

  <div class="confirmation__button-container">
    <fr-button
      class="confirmation__submit-button"
      type="button"
      [color]="ButtonColorsEnum.SECONDARY_LIGHT"
      icon="cross.svg"
      (clickButton)="onCancel()"
    >
      Cancel
    </fr-button>

    <fr-button
      class="confirmation__submit-button"
      type="button"
      icon="check.svg"
      (clickButton)="onConfirm()"
    >
      Confirm
    </fr-button>
  </div>
</div>
