import { LoanPurposeEnum } from '@features/deals/enums';

export const INVESTMENT_CRITERIA_LOAN_PURPOSES = [
  {
    value: 'Investment',
    key: LoanPurposeEnum.INVESTMENT,
  },
  {
    value: 'Light Refurbishment',
    key: LoanPurposeEnum.LIGHT_REFURBISHMENT_FINANCING,
  },
  {
    value: 'Heavy Refurbishment',
    key: LoanPurposeEnum.HEAVY_REFURBISHMENT_FINANCING,
  },
  {
    value: 'Conversion',
    key: LoanPurposeEnum.CONVERSION,
  },
  {
    value: 'Ground-up Development',
    key: LoanPurposeEnum.GROUND_UP_DEVELOPMENT_FINANCING,
  },
];
