import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EventListenerService {
  constructor(private zone: NgZone, @Inject(PLATFORM_ID) private platformId: string) {}

  public addDocumentListener = (
    listenerName: string,
    handler: () => void,
    condition: () => boolean = () => true
  ): (() => void | null) => this.addListener(document, listenerName, condition, handler);

  public addDocumentListenerWithDebounce = (
    listenerName: string,
    handler: () => void,
    condition: () => boolean = () => true
  ): (() => void | null) => this.addListener(document, listenerName, condition, handler, true);

  public addWindowListener = (
    listenerName: string,
    handler: () => void,
    condition: () => boolean = () => true
  ): (() => void | null) => this.addListener(window, listenerName, condition, handler);

  public addWindowListenerWithDebounce = (
    listenerName: string,
    handler: () => void,
    condition: () => boolean = () => true
  ): (() => void | null) => this.addListener(window, listenerName, condition, handler, true);

  public removeDocumentListener = (listenerName: string, handler: () => void): void => {
    this.removeListener(document, listenerName, handler);
  };

  public removeWindowListener = (listenerName: string, handler: () => void): void => {
    this.removeListener(window, listenerName, handler);
  };

  public addListener = (
    globalObj,
    listenerName: string,
    condition: () => boolean,
    handler: () => void,
    isDebounce = false
  ): (() => void | null) => {
    if (!isPlatformBrowser(this.platformId)) {
      return null;
    }

    let timer;

    function internalHandler() {
      if (condition()) {
        if (isDebounce) {
          if (timer) clearTimeout(timer);
          timer = setTimeout(() => {
            this.zone.run(() => handler());
          }, 150);
        } else {
          this.zone.run(() => handler());
        }
      }
    }

    const internalHandlerWithContext = internalHandler.bind(this);

    this.zone.runOutsideAngular(() => {
      globalObj.addEventListener(listenerName, internalHandlerWithContext);
    });

    return internalHandlerWithContext;
  };

  private removeListener = (globalObj, listenerName: string, handler: () => void): void => {
    if (handler) {
      globalObj.removeEventListener(listenerName, handler);
    }
  };
}
