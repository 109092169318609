export enum AssetTypeEnum {
  MIXED = 'Mixed-Use',
  MULTI_ASSETS = 'Multi-Assets',
  OFFICE = 'Office',
  RETAIL = 'Retail',
  LOGISTICS = 'Logistics',
  INDUSTRIAL = 'Industrial',
  HOTEL = 'Hotel',
  STUDENT_ACCOMMODATION = 'StudentAccommodation',
  PRIVATE_RENTED_SECTOR = 'PrivateRentedSector',
  DATA_CENTER = 'DataCenter',
  CAR_PARK = 'CarPark',
  BUILT_TO_SELL = 'BuitToSell',
  LIFE_SCIENCES = 'LifeSciences',
  SELF_STORAGE = 'Self-Storage',
  HEALTHCARE = 'Healthcare',
  LEISURE = 'Leisure',
  DEFAULT = 'default',
}
