import { ChangeDetectionStrategy, Component, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';

import { AccountTypeEnum } from '@shared/enums';
import { INavigationLinkModel } from '@features/navigation/models/i-navigation-link.model';
import { generateCopyright } from '@shared/utils';
import { IUserSidebarInfoModel } from '@features/navigation/models';
import { ACCOUNT_TYPES_LABEL } from '@shared/constants';
import { SidebarAdditionalAccountTypesEnum } from '@features/navigation/enums';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  public menuLinksList: INavigationLinkModel[] = [];

  @Input()
  public set sideMenuLinks(value: INavigationLinkModel[]) {
    this.menuLinksList = value;
    this.cdRef.markForCheck();
  }

  @Input()
  public profileLink: INavigationLinkModel;

  @Input()
  public isNavigationCollapsed: boolean;

  @Input()
  public currentNavigationItem: string;

  @Input()
  public profileInfo: IUserSidebarInfoModel;

  @Input()
  public accountType: AccountTypeEnum | SidebarAdditionalAccountTypesEnum;

  @Output()
  public navigationToggled: EventEmitter<void> = new EventEmitter();

  @Output()
  public navigationItemChanged: EventEmitter<string> = new EventEmitter();

  @Output()
  public logout: EventEmitter<void> = new EventEmitter();

  @Output()
  public switchAccount: EventEmitter<void> = new EventEmitter();

  @Output()
  public userGuideClick: EventEmitter<void> = new EventEmitter();

  public copyright = generateCopyright();
  public ACCOUNT_TYPES = {
    ...ACCOUNT_TYPES_LABEL,
    [SidebarAdditionalAccountTypesEnum.ADMIN]: 'Admin',
  };
  public SidebarAdditionalAccountTypesEnum = SidebarAdditionalAccountTypesEnum;

  constructor(private cdRef: ChangeDetectorRef) {}

  public onLogout() {
    this.logout.emit();
  }

  public onSwitchAccount(event: Event) {
    event.stopPropagation();

    this.switchAccount.emit();
  }

  public onUserGuideClick(): void {
    this.userGuideClick.emit();
  }

  public trackByMenuFn(_, item: INavigationLinkModel): string {
    return item.route;
  }

  public onNavigationSelected(item: INavigationLinkModel): void {
    this.navigationItemChanged.emit(item.route);
  }
}
