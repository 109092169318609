import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { animations } from './tooltip.animation';

@Component({
  selector: 'fr-tooltip',
  styleUrls: ['./tooltip.component.scss'],
  templateUrl: './tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: animations,
})
export class TooltipComponent {
  @Input() public text = '';

  @HostBinding('@tooltip') public animation = 'leave';

  @Input()
  @HostBinding('id')
  public menuClass: string;

  @Input()
  @HostBinding('class')
  public color: string;
}
