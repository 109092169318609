import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { EventManager } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class DOMService {
  private document: Document = Object.create(null);
  private renderer: Renderer2;
  private navigator: any;

  constructor(
    private rendererFactory: RendererFactory2,
    private eventManager: EventManager,
    @Inject(DOCUMENT) private docToken: Document
  ) {
    this.document = this.docToken;
    this.navigator = window.navigator;

    this.eventManager.addEventListener(this.document as any, 'DOMContentLoaded', (event: Event) => {
      this.document = event.target as Document;
    });

    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public get activeElement() {
    return this.document.activeElement;
  }

  public get documentElement() {
    return this.document.documentElement;
  }

  public get windowNavigator() {
    return this.navigator;
  }

  public get isIE(): boolean {
    return /msie\s|trident\//i.test(this.windowNavigator?.userAgent);
  }

  public addClass(element: Element, name: string) {
    this.renderer.addClass(element, name);
  }

  public removeClass(element: Element, name: string) {
    this.renderer.removeClass(element, name);
  }

  public hasClass(element: Element, name: string) {
    return element.classList.contains(name);
  }

  public createElement(type: string) {
    return this.document.createElement(type);
  }

  public getElement(selector: string): HTMLElement {
    return this.document.querySelector(selector);
  }

  public getElements(selector: string): NodeListOf<Element> {
    return this.document.querySelectorAll(selector);
  }

  public removeElement(element: Element): void {
    if (element.remove) {
      element.remove();
    } else {
      element.parentNode.removeChild(element);
    }
  }

  public scrollIntoErrorView() {
    const errorControl: HTMLElement = this.getElement('error-handler-container');
    this.scrollElementIntoView(errorControl, { behavior: 'smooth', block: 'center' });
  }

  public scrollElementIntoView(element: HTMLElement, params: boolean | {} = true) {
    if (!element) {
      return;
    }

    element.scrollIntoView(params);
  }

  public findClosestParent(element: HTMLElement, parentSelector: string) {
    if (element?.closest) {
      return element.closest(parentSelector);
    } else {
      // FOR IE
      return this.findClosestParentIE(element, parentSelector);
    }
  }

  private findClosestParentIE(element: HTMLElement, selector: string, stopSelector = 'body') {
    let parent = null;

    while (element) {
      if (element.className.indexOf(selector) > -1) {
        parent = element;
        break;
      } else if (stopSelector && element.className.indexOf(stopSelector) > -1) {
        break;
      }
      element = element.parentElement;
    }

    return parent;
  }
}
