import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NavigationModule } from '@features/navigation';
import { ScrollDirectiveModule } from '@shared/directives/scroll-directive/scroll-directive.module';
import {
  MainContentComponent,
  MainContentContainerComponent,
  PageNotFoundComponent,
  ErrorBaseComponent,
  InternalServerErrorComponent,
} from './components';
import { BadRequestComponent } from './components/error-pages/400/400.component';

@NgModule({
  declarations: [
    MainContentContainerComponent,
    MainContentComponent,
    ErrorBaseComponent,
    PageNotFoundComponent,
    InternalServerErrorComponent,
    BadRequestComponent,
  ],
  imports: [CommonModule, NavigationModule, RouterModule, ScrollDirectiveModule],
})
export class MainContentModule {}
