import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { INavigationLinkModel } from '@features/navigation/models/i-navigation-link.model';

@Component({
  selector: 'navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationItemComponent {
  @Input()
  public isLinkActive: boolean;
  @Input()
  public link: INavigationLinkModel;

  @Input()
  public isNavigationCollapsed: boolean;

  @Output()
  public navigationSelected: EventEmitter<INavigationLinkModel> = new EventEmitter();

  public onNavigationSelected(navItem: INavigationLinkModel) {
    this.navigationSelected.emit(navItem);
  }
}
