import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GenericHttpService } from '@shared/services';
import { ICurrencyRateModel } from '@features/currency-rates/models';

@Injectable({
  providedIn: 'root',
})
export class CurrencyRatesService {
  constructor(private genericHttpService: GenericHttpService) {}

  public get(): Observable<ICurrencyRateModel[]> {
    return this.genericHttpService.get<ICurrencyRateModel[]>('currency-rates');
  }
}
