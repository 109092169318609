export const attachmentUrlToName = (url: string) => {
  if (!url || !URL) return '';

  const urlName = new URL(url).pathname;
  const lastIndex = urlName.lastIndexOf('.');
  const fileExtension = urlName.substring(lastIndex + 1);
  const filePath = urlName.substring(0, lastIndex);

  if (!filePath || !fileExtension) {
    return '';
  }

  const name = filePath.substr(0, filePath.lastIndexOf('_'));
  const fileName = name.replace(/%20/g, ' ');

  return `${fileName.substr(1)}.${fileExtension}`;
};
