export const OVERLAY_CONFIG = {
  width: '100%',
  height: '100%',
};

export const NOTIFICATIONS_OVERLAY_CONFIG = {
  hasBackdrop: false,
  panelClass: ['fr-notifications-container'],
  minWidth: 250,
};
