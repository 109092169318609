import { CompanySubtypeEnum } from '@shared/enums';

export const COMPANY_SUBTYPE_LABEL = {
  [CompanySubtypeEnum.ASSET_MANAGER]: 'Asset Manager',
  [CompanySubtypeEnum.CLEARING_BANK]: 'Clearing Bank',
  [CompanySubtypeEnum.COMMERCIAL_BANK]: 'Commercial Bank',
  [CompanySubtypeEnum.DEBT_ADVISOR]: 'Debt Advisor',
  [CompanySubtypeEnum.DEBT_FUND]: 'Debt Fund',
  [CompanySubtypeEnum.ENDOWMENT]: 'Endowment',
  [CompanySubtypeEnum.FAMILY_OFFICE]: 'Family Office',
  [CompanySubtypeEnum.HIGH_NET_WORTH_INDIVIDUAL]: 'High-Net-Worth Individual',
  [CompanySubtypeEnum.INSURANCE_COMPANY]: 'Insurance Company',
  [CompanySubtypeEnum.INVESTMENT_BANK]: 'Investment Bank',
  [CompanySubtypeEnum.INVESTMENT_MANAGER]: 'Investment Manager',
  [CompanySubtypeEnum.PENSION_FUND]: 'Pension Fund',
  [CompanySubtypeEnum.PFANDBRIEF_BANK]: 'Pfandbrief Bank',
  [CompanySubtypeEnum.PRIVATE_EQUITY_FUND]: 'Private Equity Fund',
  [CompanySubtypeEnum.REAL_ESTATE_DEVELOPER]: 'Real Estate Developer',
  [CompanySubtypeEnum.REIT]: 'REIT',
  [CompanySubtypeEnum.OTHER]: 'Other',
  [CompanySubtypeEnum.EMPTY]: 'Empty',
};
