import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModalOverlayService {
  private defaultOverlayConfig = {
    hasBackdrop: true,
    panelClass: ['generic-modal__panel'],
    backdropClass: 'generic-modal__backdrop',
    scrollStrategy: this.overlay.scrollStrategies.noop(),
  };

  private overlayRef: OverlayRef[] = [];
  private containerRef$: BehaviorSubject<{
    viewContainerRef: ViewContainerRef;
    injector: Injector;
  } | null> = new BehaviorSubject(null);
  private modalResult$: Subject<any> = new Subject();

  constructor(private overlay: Overlay) {}

  public registerCurrentContainerRef(viewContainerRef: ViewContainerRef, injector: Injector) {
    this.containerRef$.next({
      viewContainerRef,
      injector,
    });
  }

  public unregisterCurrentContainerRef() {
    this.containerRef$.next(null);
  }

  public openModal<T>(
    component: ComponentType<T>,
    overrideConfig: OverlayConfig = {},
    dataProperty?: string,
    data?: any,
    isClosePreviewsModal = true
  ) {
    // Unregistering modal before opening prevents issues with the containerRef reference not being fired properly
    // in certain scenarios when this modal-overlay.service was already injected
    this.unregisterCurrentContainerRef();
    return this.containerRef$.pipe(
      take(1),
      switchMap((containerRef) => {
        if (isClosePreviewsModal) {
          this.closeModal(null);
        }

        const configs = new OverlayConfig({
          ...this.defaultOverlayConfig,
          ...overrideConfig,
        });

        this.overlayRef.push(this.overlay.create(configs));

        const instance = this.overlayRef[this.overlayRef.length - 1].attach(
          new ComponentPortal(component, containerRef?.viewContainerRef, containerRef?.injector)
        ).instance;

        if (dataProperty && data) {
          instance[dataProperty] = data;
        }

        return this.modalResult$.pipe(take(1));
      })
    );
  }

  public closeModal(modalResult: any) {
    event?.stopPropagation();

    this.modalResult$.next(modalResult);

    if (this.overlayRef.length) {
      this.overlayRef.pop().dispose();
    }
  }
}
