import { TEXTAREA_MAX_LENGTH } from '@features/management/constants';
import { FORMAT_ERRORS, NUMBER_ERRORS, PERCENT_ERRORS, REQUIRED_ERRORS } from '@shared/constants';

export const INPUT_ERRORS = {
  NUMBER: NUMBER_ERRORS,
  PERCENT: PERCENT_ERRORS,
  REQUIRED: REQUIRED_ERRORS,
  FORMAT: FORMAT_ERRORS,
};

export const INPUT_NUMBER_ERRORS = {
  ...INPUT_ERRORS.NUMBER,
  ...INPUT_ERRORS.REQUIRED,
};

export const INPUT_PERCENT_ERRORS = {
  ...INPUT_NUMBER_ERRORS,
  ...INPUT_ERRORS.PERCENT,
};

export const TEXTAREA_ERRORS = {
  maxlength: `Field should be ${TEXTAREA_MAX_LENGTH} characters long`,
};

export const GREATER_LOAN_AMOUNT_ERROR = 'Value should be greater than minimum loan amount';
