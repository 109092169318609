import { DurationUnitEnum } from '@shared/enums';

export const DURATIONS = [
  {
    key: DurationUnitEnum.MONTHS,
    value: 'Months',
  },
  {
    key: DurationUnitEnum.YEARS,
    value: 'Years',
  },
];

export const DURATIONS_MAP = DURATIONS.reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});
