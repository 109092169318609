export const DATEPICKER_OPTIONS = {
  allowInput: false,
  allowInvalidPreload: true,
  monthSelectorType: 'static',
  clickOpens: false,
  dateFormat: 'Y-m-d',
  altInput: true,
  altFormat: 'j/n/Y',
  minDate: null,
  onClose: (_, __, ___) => {},
  onOpen: (_, __, ___) => {},
  locale: {
    firstDayOfWeek: 1,
  },
};

export const DATEPICKER_OPTIONS_INLINE = {
  inline: true,
  wrap: false,
  minDate: null,
  monthSelectorType: 'static',
  dateFormat: 'd/m/Y',
  locale: {
    firstDayOfWeek: 1,
  },
};
