import { CurrencyEnum } from '@shared/enums';

export const CURRENCIES = [
  {
    key: CurrencyEnum.GBP,
    value: 'GBP',
  },
  {
    key: CurrencyEnum.EUR,
    value: 'EUR',
  },
  {
    key: CurrencyEnum.USD,
    value: 'USD',
  },
  {
    key: CurrencyEnum.CHF,
    value: 'CHF',
  },
  {
    key: CurrencyEnum.CZK,
    value: 'CZK',
  },
  {
    key: CurrencyEnum.DKK,
    value: 'DKK',
  },
  {
    key: CurrencyEnum.NOK,
    value: 'NOK',
  },
  {
    key: CurrencyEnum.PLN,
    value: 'PLN',
  },
  {
    key: CurrencyEnum.SEK,
    value: 'SEK',
  },
];

export const CURRENCY_MAP = CURRENCIES.reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});
