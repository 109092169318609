import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input()
  public svgPath: string;

  @Input()
  public size: string;

  @Input()
  public verticalSize: string;

  public svg: string;
  public sizeClass: string;
}
