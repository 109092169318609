<div class="card" (clickedOutside)="onClickOutside()" clickOutside>
  <close-button (clickButton)="onCloseModal()"></close-button>

  <div class="switch__title has-text-weight-semibold">
    {{title}}
  </div>

  <div class="switch__switcher-container">
    <button
      *ngFor="let tab of tabs"
      class="button-no-styles switch__switcher-button"
      [class.switch__switcher-button--active]="activeTab === tab.value"
      (click)="setActiveTab(tab.value)"
    >
      {{tab.title}}
    </button>
  </div>

  <fr-button
    class="switch__submit-button"
    type="button"
    icon="check.svg"
    (clickButton)="onConfirm()"
  >
    {{buttonText}}
  </fr-button>
</div>
