import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { newsFeatureName } from '@features/news/state/selectors';
import { NewsEffects } from '@features/news/state/effects/news.effects';
import { newsFeatureReducers } from './state/reducers/news-base.reducers';

@NgModule({
  imports: [StoreModule.forFeature(newsFeatureName, newsFeatureReducers), EffectsModule.forFeature([NewsEffects])],
})
export class NewsFeatureModule {}
