export enum CompanySubtypeEnum {
  ASSET_MANAGER = 'AssetManager',
  CLEARING_BANK = 'ClearingBank',
  COMMERCIAL_BANK = 'CommercialBank',
  DEBT_ADVISOR = 'DebtAdvisor',
  DEBT_FUND = 'DebtFund',
  ENDOWMENT = 'Endowment',
  FAMILY_OFFICE = 'FamilyOffice',
  HIGH_NET_WORTH_INDIVIDUAL = 'HighNetWorthIndividual',
  INSURANCE_COMPANY = 'InsuranceCompany',
  INVESTMENT_BANK = 'InvestmentBank',
  INVESTMENT_MANAGER = 'InvestmentManager',
  PENSION_FUND = 'PensionFund',
  PFANDBRIEF_BANK = 'PfandbriefBank',
  PRIVATE_EQUITY_FUND = 'PrivateEquityFund',
  REAL_ESTATE_DEVELOPER = 'RealEstateDeveloper',
  REIT = 'Reit',
  OTHER = 'Other',
  EMPTY = 'EMPTY',
}
