// These are the file formats we want to convert to JPEG.
const FILE_FORMATS_TO_CONVERT = ['image/webp'];

/**
 * Converts any image file (supported by Canvas) into JPEG.
 * @param file
 * @returns file
 */
export const convertImageFileToJpeg = (file: File): Promise<File> =>
  new Promise((resolve, reject) => {
    /* The trick to convert any image to jpeg without any external library is made with a canvas object which renders the 
  picuture (let's say .webp file) and later returns the same image but in jpeg format. */

    // We create an in-memory FileReader in order to get the imaga data
    const reader = new FileReader();
    const img = new Image();

    reader.onload = (e) => {
      img.src = e.target.result as string;
      img.onload = onImageLoad;
      img.onerror = onImageConversionError;
    };

    const onImageLoad = () => {
      // In-memory canvas for the image rendering
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      // Convert canvas to JPEG data URL
      const dataUrl = canvas.toDataURL('image/jpeg', 1);

      // Now we convert data URL to Blob type
      fetch(dataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          // This is the file instance with the same name but .jpg extension
          const newFile = new File([blob], file.name.replace(/\.[^/.]+$/, '.jpg'), {
            type: 'image/jpeg',
          });
          resolve(newFile);
        })
        .catch(onImageConversionError);
    };

    const onImageConversionError = (error) => {
      throw new Error(error);
    };

    reader.readAsDataURL(file);
  });

/**
 * Receives an array of pictures (can be any format supported by Canvas) and converts the webp ones to jpeg, returns a unified array with all images.
 * We need to avoid users uploading pictures in WEBP since deal pictures are later send via email and Outlook does not support that format
 * @param files
 */
export const convertImageFilesFormatsForUpload = async (files: File[]): Promise<File[]> => {
  const convertedFiles = [];
  for (const file of files) {
    const finalFile = isFileConvertionNeeded(file) ? await convertImageFileToJpeg(file) : file;
    convertedFiles.push(finalFile);
  }
  return convertedFiles;
};

const isFileConvertionNeeded = (file: File): boolean => FILE_FORMATS_TO_CONVERT.includes(file.type);
