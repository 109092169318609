<div class="fr-news__container">
  <close-button (clickButton)="closeNotifications()" size="sm"></close-button>

  <div class="fr-news__title">
    <app-icon svgPath="info-circle-solid.svg" size="20" class="fr-news__titleIcon"></app-icon>
    FundRE news!
  </div>
  <div class="fr-news__sectionWrapper" [class.fr-newsElement--disabled]="dismissAllInitiated">
    <div class="fr-news__sectionWrapperSpinner"><spinner></spinner></div>

    <section *ngFor="let singleNews of allNews" class="fr-news__section" [class.fr-newsElement--disabled]="lastNewsDismissId === singleNews.id">
      <div class="fr-news__spinner"><spinner></spinner></div>
      <div class="fr-news__contentWrapper">
        <div class="fr-news__sectionTitle">{{singleNews.title}}</div>
        <div class="fr-news__sectionDescription">{{singleNews.description}}</div>
        <div class="fr-news__sectionActions">
          <fr-button size="small" color="secondary-gray" withoutIcon="true" (clickButton)='dismissNews(singleNews)'>Dismiss</fr-button>
          <fr-button size="small" color="primary-light" type="reset" icon="arrow-right-full.svg" iconSize="10" [routerLink]="[singleNews.actionLink]" >{{singleNews.actionText}}</fr-button>
        </div>
      </div>
    </section>
  </div>
  <div class="fr-news__dismissAll" *ngIf="allNews.length > 1">
    <fr-button size="small" color="secondary-gray" withoutIcon="true" (click)="dismissAll()" [class.fr-newsElement--disabled]="dismissAllInitiated">Dismiss all</fr-button>
  </div>
</div>
