export const environment = {
  isProdMode: true,
  apiUrl: 'https://api.app.dev.fundre.co',
  filesStorage: 'https://backend-filestore-fundre-dev1.s3.amazonaws.com',
  awsAmplifyConfig: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_vBhegb1ts',
    userPoolWebClientId: 'cloiigrr6ulrl1io8rdo926di',
  },
  googleMapsConfig: {
    apiKey: 'AIzaSyCg1wtpRQNhxQaPMo_-dPxMzY1lznpSnF8',
  },
};
