import { Action, createReducer, on } from '@ngrx/store';

import { INavigationCurrentItemStateModel } from '@features/navigation/models';
import { NavigationPageActions } from '@features/navigation/state/actions';
import { AppRoutesEnum } from '@app/shared/enums';

export const initialNavigationCurrentItemState: INavigationCurrentItemStateModel = {
  currentNavItem: '',
};

const reducer = createReducer(
  initialNavigationCurrentItemState,
  on(NavigationPageActions.currentItemChanged, (state, action) => ({
    ...state,
    currentNavItem: action.payload,
  })),
  on(NavigationPageActions.dealClosedStateLoaded, (state, action) => ({
    ...state,
    // Sets the navigation item depending on the deal live/closed (We sould not display live-deals item selected for closed deals)
    currentNavItem: action.payload.isClosed ? AppRoutesEnum.DEAL_SEARCH : AppRoutesEnum.DEALS,
  }))
);

export function navigationCurrentItemReducer(
  state: INavigationCurrentItemStateModel | undefined,
  action: Action
): INavigationCurrentItemStateModel {
  return reducer(state, action);
}
