import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';

import { canActivateLoggedInUser } from '@features/auth';
import { NO_PRELOAD_ROUTE_CONFIGURATION, PRELOAD_ROUTE_CONFIGURATION } from '@shared/constants';
import { AppRoutesEnum, ErrorPages } from '@shared/enums';
import {
  InternalServerErrorComponent,
  MainContentContainerComponent,
  PageNotFoundComponent,
} from './components/main-content/components';
import { BadRequestComponent } from './components/main-content/components/error-pages/400/400.component';

export const routes: Routes = [
  {
    path: AppRoutesEnum.AUTH,
    loadChildren: () => import('./+auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: MainContentContainerComponent,
    canActivate: [canActivateLoggedInUser],
    children: [
      {
        path: AppRoutesEnum.HOME,
        loadChildren: () => import('./+home/home.module').then((m) => m.HomeModule),
        data: PRELOAD_ROUTE_CONFIGURATION,
      },
      {
        path: AppRoutesEnum.MANAGEMENT,
        loadChildren: () => import('./+management/management.module').then((m) => m.ManagementModule),
        data: PRELOAD_ROUTE_CONFIGURATION,
      },
      {
        path: AppRoutesEnum.DEALS,
        loadChildren: () => import('./+deals/deals.module').then((m) => m.DealsModule),
        data: PRELOAD_ROUTE_CONFIGURATION,
      },
      {
        path: AppRoutesEnum.DEAL_SEARCH,
        loadChildren: () => import('./+deal-search/deal-search.module').then((m) => m.DealSearchModule),
        data: PRELOAD_ROUTE_CONFIGURATION,
      },
      {
        path: AppRoutesEnum.PROFILE_SEARCH,
        loadChildren: () => import('./+profile-search/profile-search.module').then((m) => m.ProfileSearchModule),
        data: PRELOAD_ROUTE_CONFIGURATION,
      },
      {
        path: AppRoutesEnum.MARKET_DATA,
        loadChildren: () => import('./+market-data/market-data.module').then((m) => m.MarketDataModule),
        data: PRELOAD_ROUTE_CONFIGURATION,
      },
      {
        path: AppRoutesEnum.PROFILE_SETTINGS,
        loadChildren: () => import('./+profile/profile.module').then((m) => m.ProfileModule),
        data: PRELOAD_ROUTE_CONFIGURATION,
      },
      {
        path: AppRoutesEnum.SYSTEM,
        children: [
          {
            path: AppRoutesEnum.SYSTEM_TENANTS,
            loadChildren: () => import('./+system-tenants/system-tenants.module').then((m) => m.SystemTenantsModule),
            data: NO_PRELOAD_ROUTE_CONFIGURATION,
          },
          {
            path: AppRoutesEnum.SYSTEM_ADMINS,
            loadChildren: () => import('./+system-admins/system-admins.module').then((m) => m.SystemAdminsModule),
            data: NO_PRELOAD_ROUTE_CONFIGURATION,
          },
          {
            path: AppRoutesEnum.SYSTEM_NEWS,
            loadChildren: () => import('./+system-news/system-news.module').then((m) => m.SystemNewsModule),
            data: NO_PRELOAD_ROUTE_CONFIGURATION,
          },
        ],
      },
      {
        path: '',
        redirectTo: AppRoutesEnum.DEFAULT,
        pathMatch: 'full',
      },
      {
        path: `${ErrorPages.E400}`,
        component: BadRequestComponent,
      },
      {
        path: `${ErrorPages.E404}`,
        component: PageNotFoundComponent,
      },
      {
        path: `${ErrorPages.E500}`,
        component: InternalServerErrorComponent,
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
  },
];

@Injectable()
export class PreloadSelectedModulesList implements PreloadingStrategy {
  public preload(route: Route, load: () => Observable<any>): Observable<any> {
    return route.data && route.data.preload ? load() : of(null);
  }
}
