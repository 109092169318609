import Runiq from 'ramda/es/uniq';
import Rpath from 'ramda/es/path';

export const getUniqueOrMixedValue = (key: string[], values: any[], mixedValue: any) => {
  const items = values.reduce((acc, current) => {
    if (Rpath(key, current)) {
      acc.push(Rpath(key, current));
    }
    return acc;
  }, []);

  const uniqueItems = Runiq(items);

  return uniqueItems.length === 0 || uniqueItems.length === 1 ? uniqueItems[0] ?? null : mixedValue;
};
