import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { InlineSvgService } from './inline-svg.service';

@Directive({
  selector: '[freInlineSvg]',
})
export class InlineSvgDirective implements OnInit, OnDestroy {
  private svgFileName: string;
  private inlineSvgServiceSub: Subscription;

  @Input() public set freInlineSvg(value: string) {
    this.svgFileName = value;
  }

  constructor(protected elementRef: ElementRef, private inlineSvgService: InlineSvgService) {}

  public ngOnInit() {
    this.inlineSvgServiceSub = this.inlineSvgService.getSvg(this.svgFileName).subscribe((data) => {
      this.insertSvg(data);
    });
  }

  public ngOnDestroy(): void {
    this.inlineSvgServiceSub?.unsubscribe();
  }

  private insertSvg(icon: string): void {
    this.elementRef.nativeElement.innerHTML = icon;
  }
}
