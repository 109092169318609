import { Action } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { ErrorHandlerActions } from '@features/error-handler/state/actions';
import { IApiErrorModel } from '@shared/models';
import { toPayload } from '@shared/utils';

export const mapErrorAction = () =>
  map<{ payload: IApiErrorModel }, Action>((action) => {
    const payload = toPayload(action) as any;
    let error = payload;
    let message;
    if (payload.error) {
      if (typeof payload.error === 'object') {
        error = payload.error;
        // Added to handle case of 'responseType: text'.
        // In that case payload.error is stringified version of actual error and payload is just wrapper
      } else {
        try {
          error = JSON.parse(payload.error);
        } catch {}
      }
    }
    if (error?.message) {
      if (Array.isArray(error.message)) {
        message = error.message.join(', ');
      } else if (typeof error.message === 'string') {
        message = error.message;
      }
    } else if (typeof error === 'string') {
      message = error;
    }

    return ErrorHandlerActions.errorOccurred({ payload: message });
  });
