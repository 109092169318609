import { Action, createReducer, on } from '@ngrx/store';
import { INewsStateModel } from '@features/news/models/i-news-state.model';
import { NewsActions } from '../actions/news.actions';

export const newsState: INewsStateModel = {
  list: [],
};

const reducer = createReducer(
  newsState,
  on(NewsActions.getUserNewsSuccess, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
);

export function newsReducer(state: INewsStateModel, action: Action) {
  return reducer(state, action);
}
