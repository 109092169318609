export enum FilterButtonTitleEnum {
  ALL_LIVE_DEALS = 'All Live Deals',
  VIEW_ALL = 'Market Deals',
  GEOGRAPHY = 'All Geographies',
  MANDATE_TYPE = 'Mandate Types',
  ASSET_TYPE = 'All Asset Types',
  LOAN_TYPE = 'All Loan Types',
  STRATEGIES = 'Strategies',
  SELECT_DATES = 'Closing Dates',
}
