export enum RouteQueryParamsEnum {
  COMPANY_NAME = 'companyName',
  EMAIL = 'email',
  EXPIRATION_DATE = 'expirationDate',
  PRIMARY_ACCOUNT_TYPE = 'primaryAccountType',
  REDIRECT_URL = 'redirectUrl',
  STATUS = 'statuses',
  TENANT_ID = 'tenantId',
  NEWS_ID = 'newsId',
  COUNTRY = 'countries',
  ASSET_TYPE = 'assetTypes',
  LOAN_TYPE = 'loanTypes',
  SEARCH = 'search',
  DATES = 'dates',
  SCOPE = 'scopes',
  TYPE = 'types',
  COMPANY_TYPES = 'companyTypes',
  MANDATE_TYPES = 'mandateTypes',
  MANDATE_COUNTIRES = 'mandateCountries',
  MANDATE_ASSET_TYPES = 'mandateAssetTypes',
  MANDATE_STRATEGIES = 'mandateStrategies',
  MANDATE_LOAN_TYPES = 'mandateLoanTypes',
  STRATEGIES = 'strategies',
  LENDER_ID = 'lenderId',
  SEARCH_LOAN_TYPE = 'searchLoanType',
  SEARCH_IS_SPONSOR_INVOLVED = 'isInvolvedAsSponsor',
  SEARCH_IS_LENDER_INVOLVED = 'isInvolvedAsLender',
  PROFILE_ID = 'profileId',
  DEAL_ID = 'dealId',
  SUB_TYPES = 'subTypes',
}
