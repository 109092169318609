import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { IAppState } from '@shared/models';
import { AppRoutesEnum } from '@shared/enums';
import { getIsBorrower } from '@features/auth/state/selectors';
import { waitUntilUserInitialized } from '@features/auth/utils';

@Injectable({
  providedIn: 'root',
})
export class SponsorUserGuard {
  constructor(private store$: Store<IAppState>, private router: Router) {}

  public canActivateChild(): Observable<boolean> {
    return this.store$.pipe(waitUntilUserInitialized(this.store$), select(getIsBorrower)).pipe(
      take(1),
      map((isSponsor) => {
        if (!isSponsor) {
          this.router.navigate([AppRoutesEnum.DEFAULT]);
          return false;
        }

        return true;
      })
    );
  }
}

export const canActivateSponsorUser: CanActivateFn = () => inject(SponsorUserGuard).canActivateChild();
