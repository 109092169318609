import RindexBy from 'ramda/es/indexBy';
import Rprop from 'ramda/es/prop';
import Rmap from 'ramda/es/map';
import Romit from 'ramda/es/omit';

export const indexById: <T extends { id: string }>(arr: T[]) => { [id: string]: T } = RindexBy(Rprop('id'));

export const mapId: <T extends { id: string }>(arr: T[]) => string[] = Rmap(Rprop('id'));

export const mapIndexedItems = <T extends { id: string }>(items: { [id: string]: T }, order: string[]): T[] =>
  order.map((id) => items[id]);

export const deleteItemFromItems = <T extends { id: string }>(
  items: { [id: string]: T },
  removeId: string
): { [id: string]: T } => Romit([removeId])(items);
