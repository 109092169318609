import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CloseButtonModule } from '@shared/components/close-button';
import { ButtonModule } from '@features/forms/button';
import { ClickOutsideModule } from '@shared/directives/click-outside';
import {
  ConfirmationOverlayComponent,
  InformationOverlayComponent,
  SwitchOverlayComponent,
  NewsOverlayComponent,
} from './components';
import { MenuModule } from '../menu/menu.module';
import { IconModule } from '@app/shared/components/icon';
import { SpinnerModule } from '../spinner';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    SwitchOverlayComponent,
    ConfirmationOverlayComponent,
    InformationOverlayComponent,
    NewsOverlayComponent,
  ],
  exports: [SwitchOverlayComponent, ConfirmationOverlayComponent, NewsOverlayComponent],
  imports: [
    CommonModule,
    A11yModule,
    ButtonModule,
    CloseButtonModule,
    ClickOutsideModule,
    MenuModule,
    IconModule,
    SpinnerModule,
    RouterModule,
  ],
})
export class OverlayModule {}
