import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '@shared/components/icon';
import { TooltipModule } from '@shared/directives/tooltip';
import { ButtonComponent } from './button.component';

@NgModule({
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
  imports: [CommonModule, IconModule, TooltipModule],
})
export class ButtonModule {}
