import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { NotificationTypeEnum } from '@features/notifications/enums';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input()
  public text: string | null;

  @Input()
  public type: NotificationTypeEnum = NotificationTypeEnum.ERROR;

  @Input()
  public closable = false;

  @Input()
  public spacing = true;

  @Output()
  public hide: EventEmitter<void> = new EventEmitter();

  public iconByType = {
    [NotificationTypeEnum.ERROR]: 'exclamation-circle.svg',
    [NotificationTypeEnum.WARNING]: 'exclamation-triangle-solid.svg',
    [NotificationTypeEnum.INFO]: 'info-circle-solid.svg',
  };
}
