import { ButtonColorsEnum } from '@app/shared/enums';
import { IProfileContacRejectInfo } from '../models/states/i-profile-contact-state.model';

export const NUMBER_OF_DAYS_FOR_RESEND = 3;
export const NUMBER_OF_DAYS_FOR_RESEND_TO_PLATFORM = 3;
export const TENANT_CONTACT_STATUSES = {
  addSuccess: 'TENANT_CONTACT_ADD_SUCCESS',
  editSuccess: 'TENANT_CONTACT_EDIT_SUCCESS',
  contactAlready: 'TENANT_CONTACT_ALREADY',
  conactAlreadyDifferentTenant: 'TENANT_CONTACT_ALREADY_DIFFERENT_TENANT',
  contactInPlatform: 'TENANT_CONTACT_IN_PLATFORM',
  contactInPlatformAndCompany: 'TENANT_CONTACT_IN_PLATFORM_IN_COMPANY',
};

export const TEANANT_CONTACT_BUTTONS = {
  [TENANT_CONTACT_STATUSES.contactInPlatform]: {
    text: 'Yes, add',
    color: ButtonColorsEnum.PRIMARY_LIGHT,
    icon: 'check.svg',
  },
  [TENANT_CONTACT_STATUSES.contactAlready]: {
    text: 'Cancel',
    color: ButtonColorsEnum.SECONDARY_LIGHT,
    icon: 'cross.svg',
  },
  [TENANT_CONTACT_STATUSES.conactAlreadyDifferentTenant]: {
    text: 'Cancel',
    color: ButtonColorsEnum.SECONDARY_LIGHT,
    icon: 'cross.svg',
  },
  [TENANT_CONTACT_STATUSES.contactInPlatformAndCompany]: {
    text: 'Cancel',
    color: ButtonColorsEnum.SECONDARY_LIGHT,
    icon: 'cross.svg',
  },
};

export const TENANT_CONTACT_ADD_ERRORS = {
  [TENANT_CONTACT_STATUSES.contactInPlatform]: (rejectInfo: IProfileContacRejectInfo, contactAddName: string) =>
    `<strong>${contactAddName}</strong> already uses FundRE and is part of the team of <strong>${rejectInfo.tenantName}</strong>.
    Do you want to add the contact details of <strong>${contactAddName}</strong> to <strong>${rejectInfo.tenantName}</strong>?`,
  [TENANT_CONTACT_STATUSES.contactInPlatformAndCompany]: (
    rejectInfo: IProfileContacRejectInfo,
    contactAddName: string
  ) => `<strong>${contactAddName}</strong> is already a contact for <strong>${rejectInfo.tenantName} </strong>.`,
  [TENANT_CONTACT_STATUSES.contactAlready]: (rejectInfo: IProfileContacRejectInfo, contactAddName: string) =>
    `<strong>${contactAddName}</strong> is already a contact for <strong>${rejectInfo.tenantName}</strong>.`,
  [TENANT_CONTACT_STATUSES.conactAlreadyDifferentTenant]: (
    rejectInfo: IProfileContacRejectInfo,
    contactAddName: string
  ) => `<strong>${contactAddName}</strong> is already a contact for <strong>${rejectInfo.tenantName}</strong>.`,
};

export const TENANT_CONTACT_EDIT_ERRORS = {
  [TENANT_CONTACT_STATUSES.contactAlready]: (rejectInfo: IProfileContacRejectInfo, contactEditName: string) =>
    `The new contact email for <strong>${contactEditName}</strong> is already used by <strong>${rejectInfo.contactName}</strong> of <strong>${rejectInfo.tenantName}</strong>.`,
  [TENANT_CONTACT_STATUSES.contactInPlatform]: (rejectInfo: IProfileContacRejectInfo, contactEditName: string) =>
    `The new contact email for <strong>${contactEditName}</strong> is already used by <strong>${rejectInfo.contactName}</strong> of <strong>${rejectInfo.tenantName}</strong>. 
    Please add <strong>${contactEditName}</strong> as a contact to  <strong>${rejectInfo.tenantName}</strong>.`,
  [TENANT_CONTACT_STATUSES.conactAlreadyDifferentTenant]: (
    rejectInfo: IProfileContacRejectInfo,
    contactEditName: string
  ) =>
    `The new contact email for <strong>${contactEditName}</strong> is already used by <strong>${rejectInfo.contactName}</strong> of <strong>${rejectInfo.tenantName}</strong>.`,
};
