export enum LoanPurposeEnum {
  INVESTMENT = 'Investment',
  ACQUISITION_FINANCING = 'AcquisitionFinancing',
  REFINANCING = 'Refinancing',
  RECAPITALISATION = 'Recapitalisation',
  GROUND_UP_DEVELOPMENT_FINANCING = 'GroundUpDevelopmentFinancing',
  HEAVY_REFURBISHMENT_FINANCING = 'HeavyRefurbishmentFinancing',
  CONVERSION = 'Conversion',
  LIGHT_REFURBISHMENT_FINANCING = 'LightRefurbishmentFinancing',
  DEFAULT = 'default',
}
