import { createSelector } from '@ngrx/store';

import { mapIndexedItems } from '@shared/utils';
import { getCurrencyRatesState } from './currency-rates-base.selectors';

export const getCurrencyRatesItems = createSelector(getCurrencyRatesState, (state) => state.items);

export const getCurrencyRatesOrder = createSelector(getCurrencyRatesState, (state) => state.order);

export const getCurrencyRates = createSelector(getCurrencyRatesItems, getCurrencyRatesOrder, mapIndexedItems);

export const getCurrencyRatesMap = createSelector(getCurrencyRates, (currencyRates) =>
  currencyRates.reduce((acc, curr) => ({ ...acc, [curr.currency]: curr.rate }), {})
);
