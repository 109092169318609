import { IInvestmentCriteriaReadModel, ILendingCriteriaReadModel } from '@features/management';
import { CurrencyEnum, DurationPropertyEnum, DurationUnitEnum, MoneyPropertyEnum } from '@shared/enums';
import { MaximumLeveragePointPropertyEnum, MinimumReturnTypeEnum } from '@features/criteria-card/enums';
import { ThresholdTypeEnum } from '@features/deals/enums';

export const EMPTY_INVESTMENT_CRITERIA: IInvestmentCriteriaReadModel = {
  id: null,
  name: null,
  countries: null,
  assetClasses: null,
  strategies: null,
  purposes: null,
  typicalLeveragePointPercent: null,
  typicalTransactionSize: {
    [MoneyPropertyEnum.AMOUNT]: null,
    [MoneyPropertyEnum.CURRENCY]: CurrencyEnum.EUR,
  },
  minimumReturnPercent: null,
  includeJointVenture: null,
  equityType: null,
  minimumReturnType: MinimumReturnTypeEnum.CASH_ON_CASH,
  maximumEquityAmount: {
    amount: null,
    currency: CurrencyEnum.EUR,
  },
  minimumEquityAmount: {
    amount: null,
    currency: CurrencyEnum.EUR,
  },
  maximumMaturity: {
    value: null,
    unit: DurationUnitEnum.YEARS,
  },
};

export const EMPTY_LANDING_CRITERIA: ILendingCriteriaReadModel = {
  id: null,
  name: null,
  countries: null,
  assetClasses: null,
  strategies: null,
  loanType: null,
  includeClubDeals: null,
  purposes: null,
  minimumLoanAmount: {
    [MoneyPropertyEnum.AMOUNT]: null,
    [MoneyPropertyEnum.CURRENCY]: CurrencyEnum.EUR,
  },
  maximumLoanAmount: {
    [MoneyPropertyEnum.AMOUNT]: null,
    [MoneyPropertyEnum.CURRENCY]: CurrencyEnum.EUR,
  },
  maturity: {
    [DurationPropertyEnum.VALUE]: null,
    [DurationPropertyEnum.UNIT]: DurationUnitEnum.YEARS,
  },
  minimumReturnPercent: null,
  minimumReturnPercentType: MinimumReturnTypeEnum.IRR,
  maximumLeveragePoint: {
    [MaximumLeveragePointPropertyEnum.AMOUNT]: null,
    [MaximumLeveragePointPropertyEnum.THRESHOLD_TYPE]: ThresholdTypeEnum.LTV,
  },
};
