import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { NotificationModule } from '@features/notifications';
import { ErrorHandlerContainerComponent } from './components';
import { ErrorHandlerEffects } from './state/effects';

@NgModule({
  declarations: [ErrorHandlerContainerComponent],
  exports: [ErrorHandlerContainerComponent],
  imports: [EffectsModule.forFeature([ErrorHandlerEffects]), NotificationModule, CommonModule],
})
export class ErrorHandlerModule {}
