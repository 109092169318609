import { AssetTypeEnum } from '@features/deals/enums';

export const ASSET_TYPES = [
  {
    key: AssetTypeEnum.BUILT_TO_SELL,
    value: 'Built To Sell',
  },
  {
    key: AssetTypeEnum.CAR_PARK,
    value: 'Car Parks',
  },
  {
    key: AssetTypeEnum.DATA_CENTER,
    value: 'Data Centre',
  },
  {
    key: AssetTypeEnum.HEALTHCARE,
    value: 'Healthcare',
  },
  {
    key: AssetTypeEnum.HOTEL,
    value: 'Hotel',
  },
  {
    key: AssetTypeEnum.INDUSTRIAL,
    value: 'Industrial',
  },
  {
    key: AssetTypeEnum.LEISURE,
    value: 'Leisure',
  },
  {
    key: AssetTypeEnum.LIFE_SCIENCES,
    value: 'Life Sciences',
  },
  {
    key: AssetTypeEnum.LOGISTICS,
    value: 'Logistics',
  },
  {
    key: AssetTypeEnum.MIXED,
    value: 'Mixed-Use',
  },
  {
    key: AssetTypeEnum.OFFICE,
    value: 'Office',
  },
  {
    key: AssetTypeEnum.PRIVATE_RENTED_SECTOR,
    value: 'Private Rented Sector',
  },
  {
    key: AssetTypeEnum.RETAIL,
    value: 'Retail',
  },
  {
    key: AssetTypeEnum.SELF_STORAGE,
    value: 'Self-Storage',
  },
  {
    key: AssetTypeEnum.STUDENT_ACCOMMODATION,
    value: 'Student Accommodation',
  },
];

export const ASSET_TYPES_WITH_MULTI = [
  {
    key: AssetTypeEnum.MULTI_ASSETS,
    value: 'Multi-Assets',
  },
  ...ASSET_TYPES,
].sort((a, b) => a.value.localeCompare(b.value));

export const ASSET_TYPES_WITH_MULTI_PORTFOLIOS = [
  {
    key: AssetTypeEnum.MULTI_ASSETS,
    value: 'Multi-Assets Portfolios',
  },
  ...ASSET_TYPES,
].sort((a, b) => a.value.localeCompare(b.value));

export const ASSET_TYPES_MAP = ASSET_TYPES_WITH_MULTI.reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});
