import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IUserPreferencesUpdateModel, IUserResponseModel, IUserUpdateModel } from '@features/auth/models';
import { GenericHttpService } from '@shared/services';
import { ITenantUpdateModel } from '@shared/models';
import { FileUploadService } from '@features/files';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private genericHttpService: GenericHttpService, private fileUpload: FileUploadService) {}

  public getProfileInfo(): Observable<IUserResponseModel> {
    return this.genericHttpService.get<IUserResponseModel>('users/current');
  }

  public updateProfileInfo(updateInfo: IUserUpdateModel): Observable<void> {
    return this.fileUpload
      .uploadFile(updateInfo.picture)
      .pipe(switchMap((picture) => this.genericHttpService.put('users/current', { ...updateInfo, picture })));
  }

  public updateProfileTenant(data: ITenantUpdateModel): Observable<ITenantUpdateModel> {
    return this.fileUpload.uploadMultipleFiles([data.logoUrl, data.bannerUrl]).pipe(
      switchMap(([logoUrl, bannerUrl]) => {
        // Update tenant with generated image url from fileUpload service.
        // otherwise tenant.logoUrl gets filled with the file object instead
        // which breaks the app on runtime since logo is always expected to be the url of the image.
        const updatedModel = { ...data, logoUrl, bannerUrl };
        return this.genericHttpService
          .put<ITenantUpdateModel>('tenants', { ...data, logoUrl, bannerUrl })
          .pipe(map(() => updatedModel));
      })
    );
  }

  public updateUserPreferences(userPreferences: IUserPreferencesUpdateModel): Observable<void> {
    return this.genericHttpService.put('users/preferences/notifications', userPreferences);
  }
}
