import { INavigationLinkModel } from '@features/navigation/models/i-navigation-link.model';
import { AppRoutesEnum } from '@shared/enums';

export const NAVIGATION_MENU_LINKS: INavigationLinkModel[] = [
  {
    name: 'Home',
    route: AppRoutesEnum.HOME,
    isActive: false,
    icon: 'home.svg',
  },
  {
    name: 'Management',
    route: AppRoutesEnum.MANAGEMENT,
    isActive: false,
    icon: 'pie-chart.svg',
  },
  {
    name: 'Live deals',
    route: AppRoutesEnum.DEALS,
    isActive: false,
    icon: 'case.svg',
  },
  {
    name: 'Deal search',
    route: AppRoutesEnum.DEAL_SEARCH,
    isActive: false,
    icon: 'hand-shake.svg',
  },
  {
    name: 'Profile search',
    route: AppRoutesEnum.PROFILE_SEARCH,
    isActive: false,
    icon: 'search.svg',
  },
];

export const PROFILE_LINK = {
  name: 'Account settings',
  route: AppRoutesEnum.PROFILE_SETTINGS,
  isActive: false,
  icon: 'profile.svg',
};

export const ADMIN_MENU_LINKS: INavigationLinkModel[] = [
  {
    name: 'Companies',
    route: `${AppRoutesEnum.SYSTEM}/${AppRoutesEnum.SYSTEM_TENANTS}`,
    isActive: false,
    icon: 'home.svg',
  },
  {
    name: 'Admins',
    route: `${AppRoutesEnum.SYSTEM}/${AppRoutesEnum.SYSTEM_ADMINS}`,
    isActive: false,
    icon: 'super-admins.svg',
  },
  {
    name: 'News',
    route: `${AppRoutesEnum.SYSTEM}/${AppRoutesEnum.SYSTEM_NEWS}`,
    isActive: false,
    icon: 'system-news.svg',
  },
];
