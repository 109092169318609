import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { ButtonColorsEnum } from '@shared/enums';
import { NotificationOverlayService } from '@features/overlay/services';
import { INewsModel } from '@app/features/news/models/i-news.model';
import { Store, select } from '@ngrx/store';
import { IAppState } from '@app/shared/models';
import { NewsActions } from '@app/features/news/state';
import { getNewsList } from '@app/features/news/state/selectors/news.selectors';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'news-overlay',
  templateUrl: './news-overlay.component.html',
  styleUrls: ['./news-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsOverlayComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public allNews: INewsModel[] = [];
  public ButtonColorsEnum = ButtonColorsEnum;
  public lastNewsDismissId: string = null;
  public dismissAllInitiated = false;

  constructor(private overlayService: NotificationOverlayService, private store$: Store<IAppState>) {}

  public ngOnInit(): void {
    this.store$.pipe(takeUntil(this.unsubscribe$), select(getNewsList)).subscribe((news) => {
      // Close dialog if there are no more unread ones
      if (!news.length) {
        this.closeNotifications();
        this.unsubscribe$.next();
      }

      if (news.length) {
        this.allNews = news;
        // Mark as seen all displayed news so that it updates lastSeenAt date
        this.markAsSeen();
      }
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  public closeNotifications(): void {
    this.overlayService.closeNotifications(null);
  }

  public dismissNews(news: INewsModel): void {
    this.lastNewsDismissId = news.id;
    this.store$.dispatch(NewsActions.markAsReadNewsInitiated({ payload: [news] }));
  }

  public dismissAll(): void {
    this.store$.dispatch(NewsActions.markAsReadNewsInitiated({ payload: this.allNews }));
    this.dismissAllInitiated = true;
  }

  private markAsSeen(): void {
    this.store$.dispatch(NewsActions.markAsSeenNewsInitiated({ payload: this.allNews }));
  }
}
