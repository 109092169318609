import { COMPANY_SUBTYPE_LABEL } from '@shared/constants';
import { CompanySubtypeEnum } from '@shared/enums';

export const COMPANY_SUBTYPES: { key: string; value: string }[] = [
  {
    key: CompanySubtypeEnum.ASSET_MANAGER,
  },
  {
    key: CompanySubtypeEnum.CLEARING_BANK,
  },
  {
    key: CompanySubtypeEnum.COMMERCIAL_BANK,
  },
  {
    key: CompanySubtypeEnum.DEBT_ADVISOR,
  },
  {
    key: CompanySubtypeEnum.DEBT_FUND,
  },
  {
    key: CompanySubtypeEnum.ENDOWMENT,
  },
  {
    key: CompanySubtypeEnum.FAMILY_OFFICE,
  },
  {
    key: CompanySubtypeEnum.HIGH_NET_WORTH_INDIVIDUAL,
  },
  {
    key: CompanySubtypeEnum.INSURANCE_COMPANY,
  },
  {
    key: CompanySubtypeEnum.INVESTMENT_BANK,
  },
  {
    key: CompanySubtypeEnum.INVESTMENT_MANAGER,
  },
  {
    key: CompanySubtypeEnum.PENSION_FUND,
  },
  {
    key: CompanySubtypeEnum.PFANDBRIEF_BANK,
  },
  {
    key: CompanySubtypeEnum.PRIVATE_EQUITY_FUND,
  },
  {
    key: CompanySubtypeEnum.REAL_ESTATE_DEVELOPER,
  },
  {
    key: CompanySubtypeEnum.REIT,
  },
  {
    key: CompanySubtypeEnum.OTHER,
  },
].map(({ key }) => ({
  key,
  value: COMPANY_SUBTYPE_LABEL[key],
}));
