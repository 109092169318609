import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { ProfileActions, UserActions } from '@features/auth/state/actions';
import { InitActions } from '@shared/state/actions';
import { AccountTypeEnum, AppRoutesEnum } from '@shared/enums';
import { LocalStorageService } from '@shared/services';
import { ACCOUNT_TYPE_STORAGE_KEY } from '@features/auth/constants';
import { mapPayload } from '@shared/utils';
import { HomeActions } from '@features/home/state/actions';

@Injectable()
export class AccountTypeEffects {
  public storageAccountType$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InitActions.pageLoggedInInitialized),
      map(() => {
        const storageValue = this.localStorageService.getItem(ACCOUNT_TYPE_STORAGE_KEY);

        return Object.values(AccountTypeEnum).includes(storageValue) ? storageValue : null;
      }),
      filter(Boolean),
      map((accountType: AccountTypeEnum) => ProfileActions.accountTypeInitiated({ payload: accountType }))
    )
  );

  public accountTypeChanged$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.accountTypeChanged),
      mapPayload(),
      tap((accountType) => {
        this.router.navigate([AppRoutesEnum.DEFAULT]);
        this.localStorageService.setItem(ACCOUNT_TYPE_STORAGE_KEY, accountType);
      }),
      map(() => HomeActions.getHomeDataInitiated())
    )
  );

  public accountTypeDelete$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logoutInitiated, UserActions.invalidTokenDetected),
        tap(() => {
          this.localStorageService.removeItem(ACCOUNT_TYPE_STORAGE_KEY);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private router: Router, private localStorageService: LocalStorageService) {}
}
