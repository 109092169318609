import { inject, Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { UserActions } from '@features/auth/state/actions';
import { getIsUserVerified } from '@features/auth/state/selectors';
import { IAppState } from '@shared/models';
import { CanActivateFn } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoggedInUserGuard {
  constructor(private store$: Store<IAppState>) {}

  public canActivate(): Observable<boolean> {
    return this.store$.pipe(select(getIsUserVerified)).pipe(
      take(1),
      map((isUserVerified) => {
        if (!isUserVerified) {
          this.store$.dispatch(UserActions.invalidTokenDetected());
          return false;
        }

        return true;
      })
    );
  }
}

export const canActivateLoggedInUser: CanActivateFn = () => inject(LoggedInUserGuard).canActivate();
