export const DRAG_DROP_STYLE_CONFIG = {
  DATA_ROOM: {
    defaultColor: '#fff',
    activeColor: ' ',
    activeBorder: '4px solid #3e6eee',
    defaultBorder: '4px solid transparent',
  },
  PHOTO_GALLERY: {
    activeColor: '#bdc0d3',
    defaultColor: '#eceefa',
  },
};
