import { Action, createReducer, on } from '@ngrx/store';

import { UserActions } from '@features/auth';
import { indexById, mapId } from '@shared/utils';
import { CurrencyRatesActions } from '@features/currency-rates/state/actions';
import { ICurrencyRatesStateModel } from '@features/currency-rates/models';

export const initialCurrencyState: ICurrencyRatesStateModel = {
  items: {},
  order: [],
};

const reducer = createReducer(
  initialCurrencyState,
  on(UserActions.logoutInitiated, UserActions.invalidTokenDetected, () => initialCurrencyState),
  on(CurrencyRatesActions.getCurrencyRatesSuccess, (state, { payload }) => ({
    ...state,
    items: indexById(payload),
    order: mapId(payload),
  }))
);

export function currencyRatesReducer(
  state: ICurrencyRatesStateModel | undefined,
  action: Action
): ICurrencyRatesStateModel {
  return reducer(state, action);
}
