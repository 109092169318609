import { Directive, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

import { DOMService } from '@shared/services';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  @Output() public clickedOutside: EventEmitter<void> = new EventEmitter<void>();

  constructor(private elementRef: ElementRef, private domService: DOMService) {}

  @HostListener('document:click', ['$event'])
  public onOutsideClick(event: Event): void {
    // isClickInsideDatepicker is for handling  3-d library with own overlay
    // cdk overlay and ng2-flatpickr overlay have separate top parent component
    // isClickInsideDatepicker handle case when we have datepicker inside overlay
    // without isClickInsideDatepicker project overlay will be closed when user navigates between months or years
    if (
      !this.isClickConfirmationPopup(event) &&
      !this.isClickInsideComponentContent(event) &&
      !this.isClickInsideDatepicker(event) &&
      !this.isClickInsideLoader(event) &&
      !this.isClickInsideSelector(event)
    ) {
      this.clickedOutside.emit();
    }
  }

  private isClickInsideComponentContent(event: Event): boolean {
    return this.containsTarget(this.elementRef.nativeElement, event);
  }

  private isClickInsideDatepicker(event: Event): boolean {
    const element = this.domService.getElement('.flatpickr-calendar.open');

    return this.containsTarget(element, event);
  }

  private isClickInsideLoader(event: Event) {
    const element = this.domService.getElement('.loading-spinner__overlay');

    return this.containsTarget(element, event);
  }

  private isClickInsideSelector(event: Event) {
    const element = this.domService.getElement('.fr-select__options-container');

    return this.containsTarget(element, event);
  }

  private isClickConfirmationPopup(event: Event) {
    const element = this.domService.getElement('.confirmation.card');

    return this.containsTarget(element, event);
  }

  private containsTarget(element: HTMLElement, event: Event): boolean {
    return element && element.contains(event.target as Node);
  }
}
