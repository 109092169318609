import { AbstractControl, ValidationErrors } from '@angular/forms';

import { NUMBER_FORMAT } from '@shared/constants';

export const numberValidator = (control: AbstractControl): ValidationErrors | null => {
  if (isNaN(control.value)) {
    return { [NUMBER_FORMAT]: true };
  }

  return null;
};
