import { IUserModel } from '@features/auth/models/i-user.model';
import { IUserStorageModel } from '@features/auth/models/i-user-storage.model';
import { AccountTypeEnum } from '@shared/enums';
import { ITenantModel } from '@shared/models';

export interface IUserStateModel {
  currentStorageUser: IUserStorageModel;
  currentUser: IUserModel | null;
  currentTenant: ITenantModel;
  isUserStateInitialized: boolean;
  accountType: AccountTypeEnum;
}
