import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { STRICT_MIN } from '@shared/constants';

export const strictMinValidator =
  (min: number): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
      return null;
    }
    const value = parseFloat(control.value);

    return !isNaN(value) && value <= min ? { [STRICT_MIN]: { [STRICT_MIN]: min, actual: control.value } } : null;
  };

const isEmptyInputValue = (value: any): boolean => value == null || value.length === 0;
